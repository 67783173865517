import { action, thunk } from 'easy-peasy';
import { Http } from '../../../common';
import { ENDPOINTS } from '../../../constants';

export const model = {
    /** State */
    masterDataDetail: {
        data: {}
    }
};
