export const GROUPS = [
    {
        id: 1,
        name: "TLIP 1"
    },
    {
        id: 2,
        name: "TLIP 2"
    },
    {
        id: 3,
        name: "TLIP 3"
    }
]