import { Menu } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { buildPathNameList } from '../../common';
import { reactLocalStorage } from '../../common';
import {
  LS_USER_INFO,
  LS_ACTIVED_GROUP_ID,
  LS_SELECTED_TLIP_EVENT,
  LS_SELECTED_MONTH_EVENT,
  LS_SELECTED_DATE_EVENT,
  LS_DOC_TYPE,
  CSS_SIDE_BAR
} from '../../constants';
import './style.scss';
import {
  faWarehouse,
  faUser,
  faTable,
  faMailBulk,
  faCalendarCheck,
  faFileAlt,
  faDatabase
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ENDPOINTS } from '../../constants';

export const LeftSidebar = () => {
  const location = useLocation();

  const currentUser = reactLocalStorage.get(LS_USER_INFO) ? JSON.parse(reactLocalStorage.get(LS_USER_INFO)) : null;
  const tlipId = reactLocalStorage.get(LS_ACTIVED_GROUP_ID) ? reactLocalStorage.get(LS_ACTIVED_GROUP_ID) : (currentUser !== null) ? currentUser.group.groupId : 1;
  const groupName = (currentUser !== null && currentUser.group) ? currentUser.group.name : '';
  const currentUserRole = (currentUser !== null && currentUser.roles !== null) ? currentUser.roles[0].roleId : '';

  // Clear selected data in event screen
  if (!window.location.pathname.includes('/event')) {
    localStorage.removeItem(LS_SELECTED_TLIP_EVENT);
    localStorage.removeItem(LS_SELECTED_MONTH_EVENT);
    localStorage.removeItem(LS_SELECTED_DATE_EVENT);
  }
  // Clear selected data in document screen
  if (!window.location.pathname.includes('/document')) {
    localStorage.removeItem(LS_DOC_TYPE);
  }

  // For language
  const [t] = useTranslation('tlip');

  // Other state
  const [openKeys, setOpenKeys] = useState([]);
  // const [currentPath, setCurrentPath] = useState('');

  const reloadRoute = valuePath => () => {
    switch (valuePath) {
      case '/power-consumsion':
        if (currentUser.group.groupId === 1) {
          return '//cskh.tlip1.com:8181/cskh/Login.jsf';
        } else if (currentUser.group.groupId === 2) {
          return '//cskh.tlip2.com:8181/cskh/Login.jsf';
        } else if (currentUser.group.groupId === 3) {
          return '//mamr.tlip3.com/';
        } else {
          return '';
        }
        break;
      case '/water-consumsion':
        return '//tr.tlipgroup.com/en/index.html';
        break;
      case '/recruitment-service':
        if (currentUser.group.groupId === 3) {
          return '//tuyendung.tlipgroup.com/home.html';
        } else {
          return '';
        }
        break;
      default:
        return valuePath;
    }
  };

  return (
    <div className="left-side-bar-wrap">
      <div className="top-menu">
        <Link to={'/customer'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/' || window.location.pathname.includes('/customer') ? "active" : "")}>
          <FontAwesomeIcon className='menu-icon' icon={faWarehouse} />
          {t("menuTitle.customer")}
        </Link>
        <Link to={'/user'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname.includes('/user') ? "active" : "")}>
          <FontAwesomeIcon className='menu-icon' icon={faUser} />
          {t("menuTitle.user")}
        </Link>
        <Link to={'/content'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/content' ? "active" : "")}>
          <FontAwesomeIcon className='menu-icon' icon={faTable} />
          {t("menuTitle.content")}
        </Link>
        {currentUserRole == "1" &&
          <Link to={'/email-info'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname == '/email-info' ? "active" : "")}>
            <FontAwesomeIcon className='menu-icon' icon={faMailBulk} />
            {t("menuTitle.emailInfo")}
          </Link>
        }
        <Link to={'/event'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname.includes('/event') ? "active" : "")}>
          <FontAwesomeIcon className='menu-icon' icon={faCalendarCheck} />
          {t("menuTitle.event")}
        </Link>
        <Link to={'/document'} className={`${CSS_SIDE_BAR} ` + (window.location.pathname.includes('/document') ? "active" : "")}>
          <FontAwesomeIcon className='menu-icon' icon={faFileAlt} />
          {t("menuTitle.document")}
        </Link>
        <Link to={ENDPOINTS.MASTER_DATA} className={`${CSS_SIDE_BAR} ` + (window.location.pathname.includes(ENDPOINTS.MASTER_DATA) ? "active" : "")}>
          <FontAwesomeIcon className='menu-icon' icon={faDatabase} />
          {t("menuTitle.masterData")}
        </Link>
      </div>
    </div>
  );
};
