import { action, thunk } from 'easy-peasy';
import { ENDPOINTS } from '../../constants';
import { useLocation } from 'react-router-dom';
import { Http, handleError } from '../../common';

export const model = {
  loadingChange: false,

  /**
   * Action: Set loading forgot
   */
  setLoadingChange: action((state, payload) => {
    if (state?.loadingChange === undefined) {
      return;
    }

    state.loadingChange = payload;
  }),

  /**
   * Action: Call api to change password
   */
  changePassword: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingChange(true);

      const res = await Http.post(ENDPOINTS.RESET_PASSWORD, payload);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingChange(false);
    }
  })
};
