import 'antd/dist/antd.css';
import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {reactLocalStorage} from '../common';
import {LS_SESSION_OTP, LS_SESSION_TOKEN} from '../constants';
import store from '../core/create-store';
import {model as modelLoginOTP} from '../pages/login-otp/model';
import {model as modelLogin} from '../pages/login/model';
import {model as modelForgotPassword} from '../pages/forgot-password/model';
import {model as modelChangePassword} from '../pages/change-password/model';
import {model as modelUserDetail} from '../pages/user-detail/model';
import {model as modelCustomer} from '../pages/customer/model';
import {model as modelUser} from '../pages/user/model';
import {model as modelPermission} from '../pages/permission/model';
import {model as modelContent} from '../pages/content/model';
import {model as modelEmailInfo} from '../pages/email-info/model';
import {model as modelEvent} from '../pages/event/model';
import {model as modelDocument} from '../pages/document/model';
import {model as modelMasterData} from '../pages/master-data/model';
import {model as modelMasterDataDetail} from '../pages/master-data/master-data-detail/master-data-detail.model';
import {model as modelMailInfo} from '../pages/email-info/model';
import Blank from './blank';
import Default from './default';

// Add models
store.addModel('login', modelLogin);
store.addModel('loginOTP', modelLoginOTP);
store.addModel('forgotPassword', modelForgotPassword);
store.addModel('changePassword', modelChangePassword);
store.addModel('userDetail', modelUserDetail);
store.addModel('modelCustomer', modelCustomer);
store.addModel('modelUser', modelUser);
store.addModel('modelPermission', modelPermission);
store.addModel('modelContent', modelContent);
store.addModel('modelEmailInfo', modelEmailInfo);
store.addModel('modelEvent', modelEvent);
store.addModel('modelDocument', modelDocument);
store.addModel('modelMasterData', modelMasterData);
store.addModel('modelMasterDataDetail', modelMasterDataDetail);
store.addModel('modelMailInfo', modelMailInfo);

export default ({children}) => {
  const token =
    reactLocalStorage.get(LS_SESSION_TOKEN) ||
    sessionStorage.getItem(LS_SESSION_TOKEN);
  const isOTP = reactLocalStorage.get(LS_SESSION_OTP);

  const location = useLocation();
  if (location.pathname !== '/otp-verify' && isOTP) {
    return <Redirect to="/otp-verify"/>;
  }

  if (location.pathname !== '/login' && location.pathname !== '/forgot-password' && !token && !isOTP) {
    return <Redirect to="/login"/>;
  }

  if (location.pathname === '/login' && token) {
    return <Redirect to="/emails"/>;
  }
  if (
    location.pathname === '/login' ||
    location.pathname === '/otp-verify' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/404' ||
    location.pathname === '/403'
  ) {
    return <Blank>{children}</Blank>;
  }

  return <Default>{children}</Default>;
};
