import { action, thunk } from 'easy-peasy';
import { Http, handleError } from '../../common';
import {
	ENDPOINTS,
	LANG_MSG_INVALID_PAYLOAD
} from '../../constants';

export const model = {
	/** State */
	listTenant: {
		list: []
	},
	listUser: {
		list: [],
		total: 0,
	},
	detail: {},
	loadingList: false,
	loadingListTenant: false,
	loadingDetail: false,
	loadingSave: false,
	loadingDelete: false,

	/** Action */
	setListTenant: action((state, payload) => {
		state.listTenant = { ...payload };
	}),
	setListUser: action((state, payload) => {
		state.listUser = { ...payload };
	}),
	setDetail: action((state, payload) => {
		state.detail = { ...payload };
	}),
	setLoadingList: action((state, payload) => {
		state.loadingList = payload;
	}),
	setLoadingListTenant: action((state, payload) => {
		state.loadingListTenant = payload;
	}),
	setLoadingDetail: action((state, payload) => {
		state.loadingDetail = payload;
	}),
	setLoadingSave: action((state, payload) => {
		if (state?.loadingSave === undefined) {
			return;
		}
		state.loadingSave = payload;
	}),
	setLoadingDelete: action((state, payload) => {
		state.loadingDelete = payload;
	}),

	getListTenant: thunk(async (action, payload = {}) => {
		try {
			action.setLoadingListTenant(true);
			// const url = ENDPOINTS.TENANT;
			const url = ENDPOINTS.CUSTOM_LIST;
			const { data } = await Http.get(url, {
				params: { ...payload }
			});
			if (data) {
				action.setListTenant({
					list: data?.data?.list[0]?.tenants ?? [],
				});
				action.setLoadingListTenant(false);
			}
		} catch (err) {
			action.setListTenant({
				list: []
			});
			action.setLoadingListTenant(false);
		} finally {

		}
	}),

	getListUser: thunk(async (action, payload = {}) => {
		try {
			action.setLoadingList(true);
			const { data } = await Http.get(ENDPOINTS.USERS, {
				params: { ...payload }
			});
			if (data) {
				action.setListUser({
					list: data?.data.list ?? [],
					total: data?.data.total ?? 5,
				});
				action.setLoadingList(false);
			}
		} catch (err) {
			action.setListUser({
				list: [],
				total: 0,
			});
			action.setLoadingList(false);
		} finally {

		}
	}),

	getDetail: thunk(async (action, payload) => {
		try {
			action.setLoadingDetail(true);
			const res = await Http.get(`${ENDPOINTS.USERS}/${payload}`);

			if (res) {
				action.setDetail(res.data.data);
				action.setLoadingDetail(false);
			}

		} catch (err) {
			action.setDetail({});
			action.setLoadingDetail(false);
		}
	}),

	create: thunk(async (action, payload) => {
		try {
			if (!(payload !== null && typeof payload === 'object')) {
				throw new Error(LANG_MSG_INVALID_PAYLOAD);
			}

			action.setLoadingSave(true);

			const res = await Http.post(ENDPOINTS.USERS, payload);

			return res;
		} catch (err) {
			handleError(err);
		} finally {
			action.setLoadingSave(false);
		}
	}),

	update: thunk(async (action, payload) => {
		try {
			if (!(payload !== null && typeof payload === 'object')) {
				throw new Error(LANG_MSG_INVALID_PAYLOAD);
			}

			action.setLoadingSave(true);

			const res = await Http.put(`${ENDPOINTS.USERS}/${payload.userId}`, payload);

			return res;
		} catch (err) {
			handleError(err);
		} finally {
			action.setLoadingSave(false);
		}
	}),

	changeStt: thunk(async (action, payload) => {
		try {
			if (!(payload !== null && typeof payload === 'object')) {
				throw new Error(LANG_MSG_INVALID_PAYLOAD);
			}

			action.setLoadingSave(true);

			const res = await Http.put(`${ENDPOINTS.USERS}/${payload.userId}/status`, payload.data);

			return res;
		} catch (err) {
			handleError(err);
		} finally {
			action.setLoadingSave(false);
		}
	}),

	deleteUser: thunk(async (action, payload) => {
		try {
			action.setLoadingDelete(true);

			const res = await Http.delete(`${ENDPOINTS.USERS}/${payload}`);

			return res;
		} catch (err) {
			handleError(err);
		} finally {
			action.setLoadingDelete(false);
		}
	})

};
