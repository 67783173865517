import { action, thunk } from 'easy-peasy';
import { Http, handleError } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {

  /** State */
  listEvent: null,
  listEventInDay: {
    list: [],
    total: 0,
  },
  detail: {},
  detailUpdate: {},
  loadingList: false,
  loadingListInDay: false,
  loadingDetail: false,
  loadingDetailUpdate: false,
  loadingSave: false,
	loadingDelete: false,

  /** Action */
  setListEvent: action((state, payload) => {
    state.listEvent = {...payload};
  }),
  setListEventInDay: action((state, payload) => {
    state.listEventInDay = {...payload};
  }),
  setDetail: action((state, payload) => {
    state.detail = {...payload};
  }),
  setDetailUpdate: action((state, payload) => {
    state.detailUpdate = {...payload};
  }),
  setLoadingList: action((state, payload) => {
    state.loadingList = payload;
  }),
  setLoadingListInDay: action((state, payload) => {
    state.loadingListInDay = payload;
  }),
  setLoadingDetail: action((state, payload) => {
    if (state?.loadingDetail === undefined) {
      return;
    }
    state.loadingDetail = payload;
  }),
  setLoadingDetailUpdate: action((state, payload) => {
    if (state?.loadingDetailUpdate === undefined) {
      return;
    }
    state.loadingDetailUpdate = payload;
  }),
  setLoadingSave: action((state, payload) => {
    if (state?.loadingSave === undefined) {
      return;
    }
    state.loadingSave = payload;
  }),
	setLoadingDelete: action((state, payload) => {
		state.loadingDelete = payload;
	}),

  getEvents: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingList(true);
      const response = await Http.get(ENDPOINTS.EVENT, {
        params: {...payload}
      });
      if (response) {
        action.setListEvent(response.data);
        action.setLoadingList(false);
      }
    } catch (err) {
      action.setListEvent({});
      action.setLoadingList(false);
    } finally {

    }
  }),

  getEventInDay: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingListInDay(true);
      const response = await Http.get(ENDPOINTS.EVENT_PAGING, {
        params: {...payload}
      });
      if (response) {
        action.setListEventInDay(response.data);
        action.setLoadingListInDay(false);
      }
    } catch (err) {
      action.setListEventInDay({
        list: [],
        total: 0,
      });
      action.setLoadingListInDay(false);
    } finally {

    }
  }),

  getDetail: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingDetail(true);
      const response = await Http.get(`${ENDPOINTS.EVENT}/${payload}`, {});
      if (response) {
        action.setDetail(response.data.data);
        action.setLoadingDetail(false);
      }
    } catch (err) {
      action.setDetail({});
      action.setLoadingDetail(false);
    } finally {

    }
  }),

  getDetailUpdate: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingDetailUpdate(true);
      const response = await Http.get(`${ENDPOINTS.EVENT}/${payload}`, {});
      if (response) {
        action.setDetailUpdate(response.data.data);
        action.setLoadingDetailUpdate(false);
      }
    } catch (err) {
      action.setDetailUpdate({});
      action.setLoadingDetailUpdate(false);
    } finally {

    }
  }),

  create: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingSave(true);

      const res = await Http.post(ENDPOINTS.EVENT, payload);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),

	update: thunk(async (action, payload) => {
		try {
			if (!(payload !== null && typeof payload === 'object')) {
				throw new Error('Invalid Payload');
			}

			action.setLoadingSave(true);

			const res = await Http.put(`${ENDPOINTS.EVENT}/${payload[1].eventId}?hasPeriodical=${payload[1].hasPeriodical}`, payload[0]);

			return res;
		} catch (err) {
			// handleError(err);
		} finally {
			action.setLoadingSave(false);
		}
	}),

	deleteEvent: thunk(async (action, payload) => {
		try {
			action.setLoadingDelete(true);

			const res = await Http.delete(`${ENDPOINTS.EVENT}/${payload.eventId}?hasPeriodical=${payload.hasPeriodical}`);

			return res;
		} catch (err) {
			handleError(err);
		} finally {
			action.setLoadingDelete(false);
		}
	})
};
