/* eslint-disable jsx-a11y/anchor-is-valid */
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Input, Modal, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { reactLocalStorage } from '../../common';
import {
  BREADCRUMB_ROUTES_CONFIG,
  LS_LANGUAGE,
  LS_SESSION_TOKEN,
  LS_USER_INFO,
  LS_ACTIVED_GROUP_ID,
  LS_SELECTED_TLIP_EVENT,
  LS_SELECTED_MONTH_EVENT,
  LS_SELECTED_DATE_EVENT
} from '../../constants';
import './style.scss';
import logo from '../../assets/images/logo.png';

const { Search } = Input;
const language = [
  { value: 'en', label: 'Eng' },
  { value: 'vi', label: 'VN' }
];

const languageLocal = reactLocalStorage.get(LS_LANGUAGE);
const currentUser = reactLocalStorage.get(LS_USER_INFO) ? JSON.parse(reactLocalStorage.get(LS_USER_INFO)) : null;
let tenantName = (currentUser !== null && currentUser.tenant !== null) ? currentUser.tenant.nameVn : '';

function userIsAdmin(){
  if (currentUser !== null) {
    const roles = currentUser.roles.map(role => role.name);
    return roles.includes("ADMIN")||roles.includes("SUPER_ADMIN");
  }
  return false;
}

if(userIsAdmin() &&  (currentUser !== null && currentUser.userName !== null) ){
  tenantName = currentUser.userName;
}

export const MainHeader = () => {
  // For language
  const [t, i18n] = useTranslation('tlip');
  /**
   * map menu get item not have children
   */
  const newMenu = BREADCRUMB_ROUTES_CONFIG.filter(item => !item.children);

  /**
   * Reset before logout
   */
  const resetBeforeLogout = () => {
    Modal.confirm({
      content: `${t('message.confirmLogout')}`,
      autoFocusButton: null,
      maskClosable: false,
      keyboard: false,
      okText: t('common.ok'),
      cancelText: t('common.cancel'),
      className: "logout-modal",
      onOk: () => {
        localStorage.removeItem(LS_SESSION_TOKEN);
        localStorage.removeItem(LS_USER_INFO);
        localStorage.removeItem(LS_ACTIVED_GROUP_ID);
        localStorage.removeItem(LS_SELECTED_TLIP_EVENT);
        localStorage.removeItem(LS_SELECTED_MONTH_EVENT);
        localStorage.removeItem(LS_SELECTED_DATE_EVENT);

        window.location.href = `${process.env.REACT_APP_MANAGER_URL}/login`;
      }
    });
  };

  const changelanguage = value => {
    i18n.changeLanguage(value);
    reactLocalStorage.set(LS_LANGUAGE, value);
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="detail-user">
        <a rel="noopener noreferrer" href="/user-detail">
          {t('user.accountInfo')}
        </a>
      </Menu.Item>
      <Menu.Item key="change-pwd">
        <a rel="noopener noreferrer" href="/change-password">
          {t('login.changePassword')}
        </a>
      </Menu.Item>
      <hr />
      <Menu.Item key="logout">
        <Link
          to={"#"}
          onClick={() => resetBeforeLogout()}
        >
          {t('header.logout')}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="c-main-header">
        <div className="header-wrap">
          <a href="/">
            <img src={logo} alt="tlip Logo" height="80px" />
          </a>
          <div className="change-language-logout">
            {/* <Select
              defaultValue={languageLocal}
              options={language.map(item => {
                return {
                  label: item.label,
                  value: item.value
                };
              })}
              onChange={changelanguage}
            ></Select> */}
            <Avatar icon={<UserOutlined />} />
            <div className="hello-text">
              <div className="tenant-name">
                {t('header.hello')},
                <span className="space"></span>
                <Dropdown overlay={userMenu}>
                  <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <b>{tenantName}</b> <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
