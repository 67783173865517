import { Layout } from 'antd';
import React, { Suspense } from 'react';
import {
  LeftSidebar,
  MainFooter,
  MainHeader,
  RouterLoading
} from '../components';

const { Content } = Layout;

export default ({ children }) => {
  return (
    <Suspense fallback={<RouterLoading />}>
      <Layout className="l-default">
        <MainHeader />

        <Layout className="main-body">
          <LeftSidebar />
          <Layout>
            <Content className="main-content">{children}</Content>
          </Layout>
        </Layout>

        <MainFooter />
      </Layout>
    </Suspense>
  );
};
