export const MASTER_DATA = {
    data: [
        {
            id: 1,
            name: "Họp khách hàng",
            type: "material",
            categoryItems: []
        },
        {
            id: 2,
            name: "Quy định nội bộ",
            type: "internal",
            categoryItems: []
        },
        {
            id: 3,
            name: "Tài liệu liên quan",
            type: "related-document",
            categoryItems: []
        },
        {
            id: 4,
            name: "Hoạt động SRC",
            type: "src-activity",
            categoryItems: []
        }
    ],
    masterDataTpye: {
        internal: "internal",
        material: "material",
        related_document: "related-document",
        src_activity: "src-activity"
    },
    categories: {
        topic: "topic",
        document: "document",
        province: "province",
    }

}