import { action, thunk } from 'easy-peasy';
import { Http, handleError, reactLocalStorage } from '../../common';
import Axios from 'axios';
import { ENDPOINTS, LS_ACTIVED_GROUP_ID, LS_USER_INFO, LANG_MSG_INVALID_PAYLOAD } from '../../constants';

const currentUser = reactLocalStorage.get(LS_USER_INFO) ? JSON.parse(reactLocalStorage.get(LS_USER_INFO)) : null;
const tlipId = reactLocalStorage.get(LS_ACTIVED_GROUP_ID) ? reactLocalStorage.get(LS_ACTIVED_GROUP_ID) : (currentUser !== null) ? currentUser.group.groupId : 1;

export const model = {

  emergencyContact: {
    data: {}
  },
  groupInfo: {
    data: {}
  },
  recruitmentInfo: {
    data: {}
  },
  srcActivity: {
    data: {}
  },
  plotMap: {
    data: {}
  },
  loadingData: false,
  loadingGroupInfo: false,
  loadingEmergencyContactData: false,
  loadingDataRecruiment: false,
  loadingDataSrc: false,
  loadingSave: false,
  setGroupInfo: action((state, payload) => {
    state.groupInfo = { ...payload };
  }),
  setEmergencyContact: action((state, payload) => {
    state.emergencyContact = { ...payload };
  }),
  setRecruitmentInfo: action((state, payload) => {
    state.recruitmentInfo = { ...payload };
  }),
  setSrcActivity: action((state, payload) => {
    state.srcActivity = { ...payload };
  }),
  setPlotMap: action((state, payload) => {
    state.plotMap = { ...payload };
  }),
  setLoadingData: action((state, payload) => {
    state.loadingData = payload;
  }),
  setLoadingGroupInfo: action((state, payload) => {
    state.loadingGroupInfo = payload;
  }),
  setLoadingEmergencyContactData: action((state, payload) => {
    state.loadingEmergencyContactData = payload;
  }),
  setLoadingDataRecruiment: action((state, payload) => {
    state.loadingDataRecruiment = payload;
  }),
  setLoadingDataSrc: action((state, payload) => {
    state.loadingDataSrc = payload;
  }),
  setLoadingSave: action((state, payload) => {
    if (state?.loadingSave === undefined) {
      return;
    }
    state.loadingSave = payload;
  }),
  getGroupInfo: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingGroupInfo(true);
      const url = `${ENDPOINTS.GROUP_INFO}/${payload.groupId}`;
      // const params = { groupId: payload.groupId };
      const { data } = await Http.get(url);
      if (data) {
        action.setGroupInfo({
          data: data?.data ?? {}
        });
      }
    } catch (err) {
      console.log(err);
      action.setGroupInfo({
        data: {}
      });
    } finally {
      action.setLoadingGroupInfo(false);
    }
  }),
  getEmergencyContact: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingEmergencyContactData(true);
      const url = ENDPOINTS.GENERAL_EMERGENCY_CONTACT;
      const params = { groupId: payload.groupId };
      const { data } = await Http.get(url, { params: params });
      if (data) {
        action.setEmergencyContact({
          data: data?.data ?? {}
        });
      }
    } catch (err) {
      console.log(err);
      action.setEmergencyContact({
        data: {}
      });
    } finally {
      action.setLoadingEmergencyContactData(false);
    }
  }),
  getRecruitmentInfo: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingDataRecruiment(true);
      const url = ENDPOINTS.GENERAL_RECRUITMENT_INFO;
      const params = { groupId: payload.groupId };
      const { data } = await Http.get(url, { params: params });
      if (data) {
        action.setRecruitmentInfo({
          data: data?.data ?? {}
        });
      }
    } catch (err) {
      console.log(err);
      action.setRecruitmentInfo({
        data: {}
      });
    } finally {
      action.setLoadingDataRecruiment(false);
    }
  }),
  getSrcActivity: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingDataSrc(true);
      const url = ENDPOINTS.GENERAL_SRC_ACTIVITY;
      const params = { groupId: payload.groupId };
      const { data } = await Http.get(url, { params: params });
      if (data) {
        action.setSrcActivity({
          data: data?.data ?? {}
        });
      }
    } catch (err) {
      handleError(err);
      action.setSrcActivity({
        data: {}
      });
    } finally {
      action.setLoadingDataSrc(false);
    }
  }),
  getPlotMap: thunk(async (action, payload = {}) => {
    try {
      action.setLoadingData(true);
      const url = ENDPOINTS.GENERAL_PLOT_MAP;
      const params = { groupId: payload.groupId };
      const { data } = await Http.get(url, { params: params });
      if (data) {
        action.setPlotMap({
          data: data?.data ?? {}
        });
      }
    } catch (err) {
      console.log(err);
      action.setPlotMap({
        data: {}
      });
    } finally {
      action.setLoadingData(false);
    }
  }),

  createEmergencyContact: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingSave(true);
      const url = `${ENDPOINTS.EMERGENCY_CONTACT}?groupId=${payload.groupId}`;
      const res = await Http.post(url, payload.data);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),
  createRecruitmentInfo: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingSave(true);
      const url = `${ENDPOINTS.RECRUITMENT_INFO}?groupId=${payload.groupId}`;
      const config = {
        headers: {
          'Content-Type': 'text/html;charset=UTF-8'
        }
      }
      const res = await Http.post(url, payload.data.content, config);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),
  updateRecruitmentInfo: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingSave(true);
      const url = `${ENDPOINTS.RECRUITMENT_INFO}/${payload.data.id}`;
      const content = payload.data.content;
      const res = await Http.put(url, content);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),
  createSrcActivity: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingSave(true);
      const url = ENDPOINTS.CONTENT_SRC_ACTIVITY;
      const res = await Http.post(url + '?groupId=' + payload.groupId, payload.data);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),
  updateSrcActivity: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingSave(true);
      const url = ENDPOINTS.CONTENT_SRC_ACTIVITY;
      const res = await Http.put(url + '?id=' + payload.groupId, payload.data);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),

  uploadImage: thunk(async (action, payload) => {
    try {
      const url = ENDPOINTS.UPLOAD_IMAGE;
      const res = await Http.post(url, payload);
      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  uploadImageByPreUrl: thunk(async (action, payload) => {
    try {
      const awsHttp = Axios.create({
        baseURL: process.env.REACT_APP_API_ENDPOINT,
      });
      const res = await awsHttp.put(payload.predesignedUrl, payload.file);
      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  getPresignedUrl: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS.REPORTS}/generate-presigned-url`;
      const res = await Http.post(url, payload);
      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  getImgContentUrl: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS.REPORTS}/get-file-url`;
      const res = await Http.post(url, payload);
      return res;
    } catch (err) {
      handleError(err);
    }
  }),
};
