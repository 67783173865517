import { action, thunk } from 'easy-peasy';
import Axios from 'axios';
import { handleError, Http, commonDownloadFile } from '../../common';
import {
  ENDPOINTS,
  LANG_MSG_INVALID_PAYLOAD
} from '../../constants';

export const model = {
  /** State */
  dataDocumentList: {
    list: [],
    total: 0
  },
  document: {},
  masterData: {
      data: []
  },

  loadingList: false,
  loadingSave: false,
  loadingDetail: false,
  loadingDownload: false,
  loadingMaster: false,

  /** Action */
  setLoadingSave: action((state, payload) => {
    state.loadingSave = payload;
  }),
  setLoadingList: action((state, payload) => {
    state.loadingList = payload;
  }),
  setLoadingDetail: action((state, payload) => {
    state.loadingDetail = payload;
  }),
  setLoadingMaster: action((state, payload) => {
    state.loadingMaster = payload;
  }),
  setLoadingDownload: action((state, payload) => {
    state.loadingDownload = payload;
  }),
  setDataList: action((state, payload) => {
    state.dataDocumentList = {...payload};
  }),
  setDocument: action((state, payload) => {
    state.document = {...payload};
  }),
  setDataDocument: action((state, payload) => {
    // process document type, topic, province
    if (payload !== null) {
      const provinceItem = [];
      const documentItem = [];
      const topicItem = [];
      const provinceIds = [];
      const typeIds = [];
      const topicIds = [];
      let cate = null;
      if (payload.categoryItems?.length > 0) {
        for (let j = 0; j < payload.categoryItems.length; j++) {
          const itemCate = payload.categoryItems[j];
          if (payload.type.reportTypeId == 3) {
            if (itemCate.category == 'province' && itemCate.subCategory.length > 0) {
              for (let k = 0; k < itemCate.subCategory.length; k++) {
                const provinceData = itemCate.subCategory[k].split(':');
                provinceItem.push(provinceData[1]);
                provinceIds.push(provinceData[0]);
              }
            }
            if (itemCate.category == 'document' && itemCate.subCategory.length > 0) {
              for (let k = 0; k < itemCate.subCategory.length; k++) {
                const documentData = itemCate.subCategory[k].split(':');
                documentItem.push(documentData[1]);
                typeIds.push(documentData[0]);
              }
            }
            if (itemCate.category == 'topic' && itemCate.subCategory.length > 0) {
              for (let k = 0; k < itemCate.subCategory.length; k++) {
                const topicData = itemCate.subCategory[k].split(':');
                topicItem.push(topicData[1]);
                topicIds.push(topicData[0]);
              }
            }
          } else {
            const cateStr = itemCate.subCategory[0].split(':');
            cate = cateStr[0];
          }
        }
      }
      payload.typeId = (typeIds.length > 0) ? typeIds : [];
      payload.provinceId = (provinceIds.length > 0) ? provinceIds : [];
      payload.topicId = (topicIds.length > 0) ? topicIds : [];
      payload.topic = (topicItem.length > 0) ? topicItem.join(', ') : 'ALL';
      payload.document = (documentItem.length > 0) ? documentItem.join(', ') : '';
      payload.province = (provinceItem.length > 0) ? provinceItem.join(', ') : '';
      payload.cate = cate;
      if (payload.group == "TLIP1") {
        payload.tlipId = "1";
      } else if (payload.group == "TLIP2") {
        payload.tlipId = "2";
      } else {
        payload.tlipId = "3";
      }
    }
    console.log(payload, '---payload----');

    state.document = {...payload};
  }),


  getListDocument: thunk(async (action, payload) => {
    try {
      action.setLoadingList(true);
      console.log(payload,'--payload---');
      let url = `${ENDPOINTS.DOCUMENT_LIST}/` + payload.reportType + `?groupId=${payload.groupId}&page=${payload.page}&size=${payload.size}&startDate=${payload.startDate}&endDate=${payload.endDate}&fileName=${payload.fileName}&uploader=${payload.uploader}`;

      if (payload.attributes.length > 0) {
        payload.attributes.map(item => {
          url += `&attributes=${item}`;
        });
      }
      const { data } = await Http.get(url);
      const listData = data?.list ?? [];
      if (data) {
        action.setDataList({
          list: listData,
          total: data?.total ?? 0
        });
      }
    } catch (err) {
      action.setDataList({
        list: [],
        total: 0
      });
    } finally{
      action.setLoadingList(false);
    }
  }),
  setMasterData: action((state, payload) => {
      state.masterData = { ...payload };
  }),
  getMasterData: thunk(async (action, payload = {}) => {
    try {
        action.setLoadingMaster(true);
        const url = ENDPOINTS.MASTER_DATA;
        const { data } = await Http.get(url, {});
        if (data) {
            action.setLoadingMaster(false);
            action.setMasterData({
                data: data?.data ?? []
            });
        }
    } catch (err) {
        action.setMasterData({
            data: []
        });
    } finally{
      action.setLoadingMaster(false);
    }
  }),
  getDocument: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingDetail(true);
      const url = `${ENDPOINTS.DOCUMENT_DETAIL}/${payload.id}`;
      const { data } = await Http.get(url);
      if (data) {
        action.setLoadingDetail(false);
        action.setDataDocument(data.data ?? {});
      }
    } catch (err) {
      action.setDataDocument({});
    } finally{
      action.setLoadingDetail(false);
    }
  }),

  create: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingSave(true);
      const url = ENDPOINTS.DOCUMENT_CREATE;
      const res = await Http.post(url, payload);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),

  update: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingSave(true);
      const url = ENDPOINTS.DOCUMENT_UPDATE;
      const res = await Http.put(url, payload);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),
  deleteDocumentItem: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error(LANG_MSG_INVALID_PAYLOAD);
      }
      action.setLoadingSave(true);
      const url = `${ENDPOINTS.DOCUMENT_DELETE}`;
      //const url = `${ENDPOINTS.DOCUMENT_DELETE}/${payload.id}`;
      console.log(payload, '----payload-');
      const res = await Http.delete(url, { data: payload });
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),
  downloadFile: thunk(async (action, payload = {}) => {
    try {
      const url = ENDPOINTS.REPORT_DOWNLOAD;
      const data = await Http.get(url, {
        params: { file: payload.file },
        responseType: 'blob'
      });
      if (data) {
        commonDownloadFile(data, payload);
        setTimeout(() => {
          action.setLoadingDownload(false)
        }, 200);
      }
    } catch (err) {
      setTimeout(() => {
        action.setLoadingDownload(false)
      }, 200);
    }
  }),

  uploadImageByPreUrl: thunk(async (action, payload) => {
    try {
      const awsHttp = Axios.create({
        baseURL: process.env.REACT_APP_API_ENDPOINT,
      });
      const res = await awsHttp.put(payload.predesignedUrl, payload.file);
      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  getPresignedUrl: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS.REPORTS}/generate-presigned-url`;
      const res = await Http.post(url, payload);
      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  getPresignedUrlDocument: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error('Invalid Payload');
      }
      const url = `${ENDPOINTS.REPORTS}/download-url?file=${payload.file}`;
      const res = await Http.get(url);
      if (res) {
        return res;
      }
    } catch (err) {
      return err;
    } finally {
    }
  }),
};
