import { Modal, notification } from 'antd';
import Axios from 'axios';
import i18next from 'i18next';
import { getObjectByValue } from '../common';
import {
  HTTP_STATUS_CODES,
  LS_ACTIVED_GROUP_ID,
  LS_SESSION_TOKEN,
  LS_USER_INFO,
  LANG_COMMON_ERROR, ENDPOINTS
} from '../constants';

const token = localStorage.getItem(LS_SESSION_TOKEN);
const Http = Axios.create({
  baseURL: ENDPOINTS.API_URL_BASE,
  headers: { Authorization: `${token}` }
});

//Response intorceptor
Http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const status = error.response?.status;
    const message = error.response.data?.message;

    if (window.location.pathname !== '/login') {
      if (window.location.pathname === '/forgot-password') {
        if (status === 404) {
          notification.error({
            description: i18next.t('tlip:message.usernameOrEmailNotFound')
          });
        }
        if (status === 401) {
          if (message == 'Unauthorized') {
            notification.error({
              description: i18next.t('tlip:message.roleUnableToLogin')
            });
          } else {
            notification.error({
              description: i18next.t('tlip:message.inactiveAccount')
            });
          }
        }
      } else if (window.location.pathname === '/user') {
          if (status === 417) {
            if (error.response?.data?.message == 'Tenant is inactive, can not update user status') {
              notification.error({
                message: i18next.t(LANG_COMMON_ERROR),
                description: i18next.t('tlip:message.cannotDeativeUserCauseTenantIsInactive')
              });
            }
          }
      } else if (window.location.pathname === '/user/create' || window.location.pathname === '/user/edit') {
          if (status === 417) {
            if (error.response?.data?.message == 'email already exists') {
              notification.error({
                message: i18next.t(LANG_COMMON_ERROR),
                description: i18next.t('tlip:message.emailExist')
              });
            } else {
              notification.error({
                message: i18next.t(LANG_COMMON_ERROR),
                description: i18next.t('tlip:message.usernameExist')
              });
            }
          }
      } else if (window.location.pathname === '/change-password') {
        if (status === 404) {
          notification.error({
            description: i18next.t('tlip:message.wrongOldPassword')
          });
        }
      } else if (window.location.pathname === '/event/create' || window.location.pathname === '/event/edit') {
        if (status === 400) {
          if (message == 'beginDate cannot be the past date') {
            notification.error({
              message: i18next.t(LANG_COMMON_ERROR),
              description: i18next.t('tlip:message.beginDateLessThanCurrentDate')
            });
          }
        }
      } else if (window.location.pathname === '/document/create' || window.location.pathname === '/document/edit') {
        if (status === 409) {
          notification.error({
            message: i18next.t(LANG_COMMON_ERROR),
            description: i18next.t('tlip:message.documentNameExisted')
          });
        }
      }
      else {
        if (status === 401) {
          localStorage.removeItem(LS_SESSION_TOKEN);
          localStorage.removeItem(LS_USER_INFO);
          localStorage.removeItem(LS_ACTIVED_GROUP_ID);

          window.location.href = `${process.env.REACT_APP_MANAGER_URL}/login`;
        } else {
          notification.error({
            message: HTTP_STATUS_CODES.some(item => item.value === status)
              ? getObjectByValue(status, HTTP_STATUS_CODES)?.label
              : i18next.t(LANG_COMMON_ERROR),
            description: error.response?.data?.message || ''
          });
        }
      }
    }

    return Promise.reject(error.response ? error.response : error);
  }
);

export { Http };
