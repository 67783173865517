import { action, thunk } from 'easy-peasy';
import { Http } from '../../common';
import { ENDPOINTS } from '../../constants';
import { useLocation } from 'react-router-dom';

export const model = {
  /** State */
  detail: {},
  loading: false,

  /** Action */
  getDetail: action((state, payload) => {
    state.detail = {...payload};
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  getUserDetail: thunk(async (action, payload = {}) => {
    action.setLoading(true);
    try {
      const url = ENDPOINTS.USER_DETAIL;
      const { data } = await Http.get(url, {});
      if (data) {
        console.log("detail", data.data);
        action.getDetail(data.data);
        action.setLoading(false);
      }
    } catch (err) {
      action.getDetail({});
      action.setLoading(false);
    }
  })
};
