import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http } from '../../common';

export const model = {
  /**
   * State
   */
  loadingLogin: false,

  /**
   * Action: Set loading login
   */
  setLoadingLogin: action((state, payload) => {
    if (state?.loadingLogin === undefined) {
      return;
    }

    state.loadingLogin = payload;
  }),

  /**
   * Action: Call api to login
   */
  login: thunk(async (action, payload) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingLogin(true);

      const url = ENDPOINTS.LOGIN;
      const res = await Http.post(url, payload).then(res => res.data);

      return res;
    } catch (err) {
      return err;
    } finally {
      action.setLoadingLogin(false);
    }
  }),
};
