import { action, createStore, thunk } from 'easy-peasy';

import { ENDPOINTS, DEFAULT_SORT, LS_USER_INFO } from '../constants';
import {
  Http,
  jsonParse,
  buildQuery,
  handleError,
  removeDuplicate,
  reactLocalStorage
} from '../common';

export default createStore({
  global: {
    /**
     * State
     */
    globalLanguage: '',
    globalUserInfo: {},
    globalRoleInfo: {},
    globalAssignedPermission: {}, // Assigned permission list for current page
    allGlobalProject: [],
    allGlobalRelease: [],
    globalProject: {},
    globalRelease: {},
    searchFieldList: null,
    loadingGlobalUserInfo: false,
    loadingAllGlobalProject: false,
    loadingAllGlobalRelease: false,
    loadingSearchFieldList: false,

    /**
     * Action: Set global language
     */
    setGlobalLanguage: action((state, payload) => {
      if (!(state?.globalLanguage !== undefined)) {
        return;
      }

      state.globalLanguage = payload;
    }),
    /**
     * Action: Set global user info
     */
    setGlobalUserInfo: action((state, payload) => {
      if (!(state?.globalUserInfo !== undefined)) {
        return;
      }

      const oldUserInfo = JSON.stringify(state.globalUserInfo);
      const newUserInfo = JSON.stringify(payload);

      if (payload && newUserInfo !== oldUserInfo) {
        state.globalUserInfo = payload;
      }
    }),
    /**
     * Action: Set global role info
     */
    setGlobalRoleInfo: action((state, payload) => {
      if (!(state?.globalRoleInfo !== undefined)) {
        return;
      }

      if (payload && state.globalRoleInfo.id !== payload.id) {
        state.globalRoleInfo = payload;
      }
    }),

    /**
     * Action: Set global permission for current page
     */
    setGlobalAssignedPermission: action((state, payload) => {
      if (!(state?.globalAssignedPermission !== undefined)) {
        return;
      }

      if (
        payload &&
        state.globalAssignedPermission.moduleId !== payload.moduleId
      ) {
        state.globalAssignedPermission = payload;
      }
    }),

    /**
     * Action: Set all global project
     */
    setAllGlobalProject: action((state, payload) => {
      if (!(state?.allGlobalProject !== undefined)) {
        return;
      }

      if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
        state.allGlobalProject = [];
        return;
      }

      state.allGlobalProject = payload.rows;
    }),

    /**
     * Action: Set all global release
     */
    setAllGlobalRelease: action((state, payload) => {
      if (!(state?.allGlobalRelease !== undefined)) {
        return;
      }

      if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
        state.allGlobalRelease = [];
        return;
      }

      state.allGlobalRelease = payload.rows;
    }),

    /**
     * Action: Set current project
     */
    setGlobalProject: action((state, payload) => {
      if (!(state?.globalProject !== undefined)) {
        return;
      }

      if (payload && state.globalProject.id !== payload.id) {
        state.globalProject = payload;
      }
    }),

    /**
     * Action: Set current release
     */
    setGlobalRelease: action((state, payload) => {
      if (!(state?.globalRelease !== undefined)) {
        return;
      }

      if (payload && state.globalRelease.id !== payload.id) {
        state.globalRelease = payload;
      }
    }),

    /**
     * Action: Set field list for search
     */
    setSearchFieldList: action((state, payload) => {
      if (!(state?.searchFieldList !== undefined)) {
        return;
      }

      if (!(Array.isArray(payload) && payload.length)) {
        state.searchFieldList = [];
        return;
      }

      state.searchFieldList = payload;
    }),

    /**
     * Action: Set loading global user info
     */
    setLoadingGlobalUserInfo: action((state, payload) => {
      if (!(state?.loadingGlobalUserInfo !== undefined)) {
        return;
      }

      state.loadingGlobalUserInfo = payload;
    }),

    /**
     * Action: Set loading all global project
     */
    setLoadingAllGlobalProject: action((state, payload) => {
      if (!(state?.loadingAllGlobalProject !== undefined)) {
        return;
      }

      state.loadingAllGlobalProject = payload;
    }),

    /**
     * Action: Set loading all global release
     */
    setLoadingAllGlobalRelease: action((state, payload) => {
      if (!(state?.loadingAllGlobalRelease !== undefined)) {
        return;
      }

      state.loadingAllGlobalRelease = payload;
    }),

    /**
     * Action: Set loading field list for search
     */
    setLoadingSearchFieldList: action((state, payload) => {
      if (!(state?.loadingSearchFieldList !== undefined)) {
        return;
      }

      state.loadingSearchFieldList = payload;
    }),

    /**
     * Action: Call api to get global user info
     */
    getGlobalUserInfo: thunk(async (action, payload) => {
      try {
        action.setLoadingGlobalUserInfo(true);

        const hasReload = payload?.hasReload;
        const url = ENDPOINTS.USER_INFO;
        const res = await Http.get(url).then(res => res.data);

        if (!res?.data) {
          action.setGlobalUserInfo({});
          throw res;
        }

        const roles = Array.isArray(res.data.roles)
          ? res.data.roles
              .filter(
                item =>
                  item.role?.id &&
                  Array.isArray(item.role.permissions) &&
                  item.role.permissions.length
              )
              .map(item => {
                const permissions = [...item.role.permissions].filter(sub => {
                  const permissionArr = jsonParse(sub.permission);
                  return Array.isArray(permissionArr) && permissionArr.length;
                });

                return {
                  ...item,
                  role: { ...item.role, permissions }
                };
              })
          : [];

        const newData = { ...res.data, roles };

        action.setGlobalUserInfo(newData);
        reactLocalStorage.setObject(LS_USER_INFO, newData);

        if (hasReload) {
          window.location.reload();
        }

        return newData;
      } catch (err) {
        handleError(err);
      } finally {
        action.setLoadingGlobalUserInfo(false);
      }
    }),

    /**
     * Action: Call api to get all global project
     */
    getAllGlobalProject: thunk(async (action, payload) => {
      try {
        if (!payload) {
          throw new Error('Invalid Payload');
        }

        action.setLoadingAllGlobalProject(true);

        // Get mapping project list for current user
        const params = { limit: null, order: null, where: { userId: payload } };
        const url = `${ENDPOINTS.PROJECT_USER}?q=${buildQuery(params)}`;
        const res = await Http.get(url).then(res => res.data);

        if (!(Array.isArray(res?.data?.rows) && res.data.rows.length)) {
          action.setAllGlobalProject([]);
          throw res;
        }

        // Get project list for current user
        const userInfoLocal = reactLocalStorage.getObject(LS_USER_INFO);
        const params2 = {
          page: 1,
          limit: null,
          order: [DEFAULT_SORT],
          where: {
            id: res.data.rows.map(item => item.projectId)
          }
        };

        if (!userInfoLocal?.isRoot) {
          params2.where = {
            ...params2.where,
            tenantId: userInfoLocal?.tenantId || null
          };
        }

        const ur2 = `${ENDPOINTS.PROJECT}?q=${buildQuery(params2)}`;
        const res2 = await Http.get(ur2).then(res => res.data);

        if (!res2.data) {
          throw res2;
        }

        action.setAllGlobalProject(res2.data);
      } catch (err) {
        handleError(err);
      } finally {
        action.setLoadingAllGlobalProject(false);
      }
    }),

    /**
     * Action: Call api to get all release list
     */
    getAllGlobalRelease: thunk(async (action, payload) => {
      try {
        action.setLoadingAllGlobalRelease(true);

        const params = { ...payload, limit: null };
        const url = `${ENDPOINTS.RELEASE}?q=${buildQuery(params)}`;
        const res = await Http.get(url).then(res => res.data);

        if (!res?.data) {
          action.setAllGlobalRelease(null);
          throw res;
        }

        action.setAllGlobalRelease(res.data);
      } catch (err) {
        handleError(err);
      } finally {
        action.setLoadingAllGlobalRelease(false);
      }
    }),

    /**
     * Action: Call api to get field list for search
     */
    getSearchFieldList: thunk(async (action, payload) => {
      try {
        action.setLoadingSearchFieldList(true);

        const url = `${ENDPOINTS.SEARCH_GET_FIELD}?type=${payload}`;
        const res = await Http.get(url).then(res => res.data);

        if (!res?.data) {
          action.setSearchFieldList([]);
          throw res;
        }

        const newData = removeDuplicate(res.data, 'field');

        action.setSearchFieldList(newData);
      } catch (err) {
        handleError(err);
      } finally {
        action.setLoadingSearchFieldList(false);
      }
    })
  }
});
