import { action, thunk } from 'easy-peasy';
import { Http, handleError } from '../../common';
import {
    ENDPOINTS,
    LANG_MSG_INVALID_PAYLOAD
} from '../../constants';

export const model = {
    /** State */
    masterData: {
        data: []
    },
    masterDataDetail: {
        data: []
    },
    loadingList: false,
    loadingSave: false,

    /** Action */
    setLoadingList: action((state, payload) => {
        state.loadingList = payload;
    }),
    setLoadingSave: action((state, payload) => {
        if (state?.loadingSave === undefined) {
            return;
        }
        state.loadingSave = payload;
    }),
    setMasterData: action((state, payload) => {
        state.masterData = { ...payload };
    }),

    getMasterData: thunk(async (action, payload = {}) => {
        try {
            action.setLoadingList(true);
            const url = ENDPOINTS.MASTER_DATA;
            const { data } = await Http.get(url, {});
            if (data) {
                action.setMasterData({
                    data: data?.data ?? []
                });
            }
        } catch (err) {
            action.setMasterData({
                data: []
            });
        } finally {
            action.setLoadingList(false);
        }
    }),

    setMasterDataDetail: action((state, payload) => {
        state.masterDataDetail = { ...payload };
    }),
    addDataDetail: action((state, payload) => {
        state.masterDataDetail.data.push(payload);
    }),

    getMasterDataDetail: thunk(async (action, payload = {}) => {
        try {
            const url = ENDPOINTS.MASTER_DATA + '/' + payload.type;
            const { data } = await Http.get(url, {});
            if (data) {
                action.setMasterDataDetail({
                    data: data?.data ?? []
                });
            }
        } catch (err) {
            console.log(err);
            action.setMasterDataDetail({
                data: []
            });
        }
    }),

    updateMasterDataDetail: thunk(async (action, payload = {}) => {
        try {
            if (!(payload !== null && typeof payload === 'object')) {
                throw new Error(LANG_MSG_INVALID_PAYLOAD);
            }

            action.setLoadingSave(true);
            const url = `${ENDPOINTS.MASTER_DATA}/${payload.id}`;

            const res = await Http.put(url, payload);

            return res;
        } catch (err) {
            handleError(err);
        } finally {
            action.setLoadingSave(false);
        }
    }),
    createMasterDataDetail: thunk(async (action, payload = {}) => {
        try {
            if (!(payload !== null && typeof payload === 'object')) {
                throw new Error(LANG_MSG_INVALID_PAYLOAD);
            }

            action.setLoadingSave(true);
            const url = `${ENDPOINTS.MASTER_DATA}`;

            const res = await Http.post(url, payload);

            return res;
        } catch (err) {
            handleError(err);
        } finally {
            action.setLoadingSave(false);
        }
    }),
    updateDataDetailStatus: thunk(async (action, payload = {}) => {
        try {
            if (!(payload !== null && typeof payload === 'object')) {
                throw new Error(LANG_MSG_INVALID_PAYLOAD);
            }

            action.setLoadingSave(true);
            const url = `${ENDPOINTS.MASTER_DATA}/${payload.id}`;

            const res = await Http.delete(url, {});

            return res;
        } catch (err) {
            handleError(err);
        } finally {
            action.setLoadingSave(false);
        }
    })

};
