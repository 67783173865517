import queryString from 'query-string';
import moment from 'moment';
import { parse } from '@rsql/parser';
import { faFileImage, faFileExcel, faFileWord, faFilePowerpoint, faFilePdf, faFile } from '@fortawesome/free-regular-svg-icons';
import fileDownload from 'js-file-download';

import {
  PAGE_SIZE,
  DEFAULT_SORT,
  SERVER_DATE_FORMAT,
  ESTIMATED_TIME_PATTERN,
  BREADCRUMB_ROUTES_CONFIG
} from '../constants';

/**
 * Check allow role
 */
export const checkAllowRole = (userInfo, roleTemplateId) => {
  if (
    !(userInfo && Array.isArray(userInfo.roles) && userInfo.roles.length) ||
    !roleTemplateId
  ) {
    return false;
  }

  const isAllowed = userInfo.roles.some(item => {
    return item?.role?.roleTemplateId === roleTemplateId;
  });

  return isAllowed;
};

/**
 * Check allow permission
 *
 * @param {object} assignedPermission - assigned permission list for current page
 * @param {number} permissionVal - permission value
 */
export const checkPermissionRole = (assignedPermission, permissionVal) => {
  if (
    !(
      Array.isArray(assignedPermission?.permissionArr) &&
      assignedPermission.permissionArr.length
    ) ||
    !permissionVal
  ) {
    return false;
  }

  const isAllowed = assignedPermission.permissionArr.includes(permissionVal);

  return isAllowed;
};

/**
 * Get assigned permission list for current page
 */
export const getAssignedPermission = (userInfo, locationPathname) => {
  if (
    !(Array.isArray(userInfo?.roles) && userInfo.roles.length) ||
    !locationPathname
  ) {
    return;
  }

  let assignedPermission = {};

  // Find breadcrumb item by pathname
  const breadcrumb = BREADCRUMB_ROUTES_CONFIG.find(item => {
    return item.path === locationPathname;
  });

  if (!breadcrumb?.moduleId) {
    return;
  }

  // Find permission info for current page
  for (let i = 0; i < userInfo.roles.length; i++) {
    const item = userInfo.roles[i];

    if (
      !(Array.isArray(item.role?.permissions) && item.role.permissions.length)
    ) {
      break;
    }

    const permissionInfo = item.role.permissions.find(sub => {
      return sub.moduleId === breadcrumb.moduleId;
    });

    if (permissionInfo) {
      const permissionValues = jsonParse(permissionInfo.permission);

      // Convert permission values (string) to array
      const permissionArr = Array.isArray(permissionValues)
        ? permissionValues
        : [];

      assignedPermission = { ...permissionInfo, permissionArr };

      break;
    }
  }

  return assignedPermission?.moduleId ? assignedPermission : {};
};

/**
 * Get path name of the first permission
 */
export const getPathNameOfTheFirstPermission = (userInfo, roleTemplateId) => {
  let pathName = '';

  if (
    !(Array.isArray(userInfo?.roles) && userInfo.roles.length) ||
    !roleTemplateId
  ) {
    return;
  }

  const roleInfo = userInfo.roles.find(
    item => item.role?.roleTemplateId === roleTemplateId
  );

  if (
    !(
      Array.isArray(roleInfo?.role?.permissions) &&
      roleInfo.role.permissions.length
    )
  ) {
    return;
  }

  const permissionModuleIds = roleInfo.role.permissions.map(item => {
    return item.moduleId;
  });

  const breadcrumb = BREADCRUMB_ROUTES_CONFIG.find(item => {
    return (
      permissionModuleIds.includes(item.moduleId) && item.path !== '/system'
    );
  });

  if (breadcrumb) {
    pathName = breadcrumb.path;
  }

  return pathName;
};

/**
 * Build and encode URI query for api
 * View: https://sequelize.org/v3/docs/querying/
 *
 * Example:
 * {
 *   offset: 0,
 *   limit: 10,
 *   order: [
 *     ['name', 'ASC']
 *   ],
 *   where: {
 *     id: 123,
 *     name: {
 *       $iLike: '%abc%'
 *     },
 *     $or: [ // search on multi attribute
 *       {
 *         name: {
 *           $iLike: '%tony%'
 *         }
 *       },
 *       {
 *         email: {
 *           $iLike: '%tony%'
 *         }
 *       }
 *     ],
 *     $and: [ // exclude record
 *       {
 *         id: { $ne: 63 }
 *       },
 *       {
 *         id: { $ne: 150 }
 *       }
 *     ]
 *   }
 * }
 *
 */
export const buildQuery = (params = {}) => {
  const query = {
    offset: 0, // Same page = 1
    limit: PAGE_SIZE // If limit is null => Don't send limit => Get all
  };

  // Add offset
  if (+params.page >= 1) {
    const page = params.page < 0 ? 1 : params.page;
    query.offset = (page - 1) * (params.limit || query.limit);
  }

  // Add limit
  if (+params.limit >= 0) {
    query.limit = params.limit < 0 ? PAGE_SIZE : params.limit;
  }

  // Add where
  if (
    params.where !== null &&
    typeof params.where === 'object' &&
    Object.keys(params.where).length
  ) {
    query.where = params.where;
  }

  // Add order (sort)
  if (!params.order) {
    query.order = [DEFAULT_SORT]; // Default is order by updatedAt: DESC
  } else if (Array.isArray(params.order) && params.order.length) {
    query.order = params.order;
  } else {}

  // Add attributes
  if (
    (Array.isArray(params.attributes) && params.attributes.length) ||
    (typeof params.attributes === 'object' &&
      Object.keys(params.attributes).length)
  ) {
    query.attributes = params.attributes;
  }

  // Don't add when value is null
  Object.keys(params).forEach(key => {
    if (key === 'page' && params.page === null) {
      delete query.offset;
    } else if (params[key] === null) {
      delete query[key];
    } else {}
  });

  return encodeURI(JSON.stringify({ ...query }));
};

export const buildQueryForAql = (params = {}) => {
  const query = {
    offset: 0, // Same page = 1
    limit: PAGE_SIZE // If limit is null => Don't send limit => Get all
  };

  // Add offset
  if (+params.page >= 1) {
    const page = params.page < 0 ? 1 : params.page;
    query.offset = (page - 1) * (params.limit || query.limit);
  }

  // Add limit
  if (+params.limit >= 0) {
    query.limit = params.limit < 0 ? PAGE_SIZE : params.limit;
  }

  // Add order (sort)
  if (!params.order) {
    query.order = `${DEFAULT_SORT[0]}|${DEFAULT_SORT[1]}`; // Default is order by updatedAt: DESC
  } else if (Array.isArray(params.order) && params.order.length) {
    query.order = params.order
      .map(item => {
        return `${item[0]}|${item[1]}`;
      })
      .join(',');
  } else {}

  if (params.aql) {
    query.aql = params.aql;
  }

  // Don't add when value is null
  Object.keys(params).forEach(key => {
    if (key === 'page' && params.page === null) {
      delete query.offset;
    } else if (params[key] === null) {
      delete query[key];
    } else {}
  });

  return queryString.stringify({ ...query });
};

/**
 * Check valid rsql string by rsql parse
 * More: https://github.com/piotr-oles/rsql#packages
 */
export const parseRsql = rsql => {
  if (rsql === undefined || rsql === null) {
    return;
  }

  try {
    return parse(rsql);
  } catch (err) {
    return null;
  }
};

/**
 * Get conditions by ast
 * More: https://github.com/piotr-oles/rsql#packages
 */
export const getConditionsByAql = aql => {
  if (!aql) {
    return;
  }

  const astObj = parseRsql(aql);

  if (!astObj) {
    return;
  }

  const conditionList = [];
  const logicList = [];

  const convert = obj => {
    const newItem = {};

    if (obj.type === 'COMPARISON') {
      newItem.selector = obj.left.selector;
      newItem.operator = obj.operator;
      newItem.value = obj.right.value;

      conditionList.push(newItem);
    }

    if (obj.type === 'LOGIC') {
      logicList.push(obj.operator);

      convert(obj.left);
      convert(obj.right);
    }
  };

  convert(astObj);

  return { conditionList, logicList };
};

/**
 * Get query after delete
 */
export const getQueryAfterDelete = (idsLength, helpers) => {
  if (
    !(
      helpers?.getState() &&
      Array.isArray(helpers.getState()['data']) &&
      helpers.getState()['query']
    )
  ) {
    return;
  }

  const dataLength = helpers.getState()['data'].length;
  const oldPage = helpers.getState()['query'].page;
  const page = dataLength === idsLength ? oldPage - 1 : oldPage;
  const query = { ...helpers.getState()['query'], page };

  return query;
};

/**
 * Build path name list, for active nav link
 *
 * Example - Before: /parent/child-1/child-2
 * Example - After: ['/parent', '/parent/child-1', '/parent/child-1/child-2']
 */
export const buildPathNameList = pathName => {
  if (!pathName) {
    return [];
  }

  const pathList = [];
  const pathArray = pathName.substr(pathName.indexOf('/') + 1).split('/'); // Example: ['parent', 'child-1', 'child-2']

  pathArray.forEach((item, index) => {
    if (!pathList[index - 1]) {
      pathList.push('/' + item);
      return;
    }

    pathList.push(pathList[index - 1] + '/' + item);
  });

  // For Defect Tracking
  if (pathName === '/defect-tracking' || pathName === '/jira-defect-tracking') {
    pathList.push('/defect-tracking-index');
  }

  return pathList;
};

/**
 * Build routes for breadcrumb
 *
 * Example - Before: /parent/child-1/child-2
 * Example - After:
 * [
 *   { path: "/", breadcrumbName: "Home" },
 *   { path: "/parent", breadcrumbName: "Parent" },
 *   { path: "/parent/child-1", breadcrumbName: "Child 1" },
 *   { path: "/parent/child-1/child-2", breadcrumbName: "Child 2" }
 * ]
 */
export const buildRoutesForBreadcrumb = (pathName, breadcrumbConfig) => {
  if (!pathName) {
    return [];
  }

  if (pathName === '/') {
    // Only home page
    return breadcrumbConfig.filter(item => item.path === '/');
  }

  const breadcrumbs = [];
  const pathList = [];
  const pathArray = pathName.split('/'); // Example: ['', 'parent', 'child-1', 'child-2']

  pathArray.forEach((item, index) => {
    if (!pathList[index - 1]) {
      const curentPath = '/' + item;
      pathList.push(curentPath);

      const found = breadcrumbConfig.find(breadcrumb => {
        return breadcrumb.path === curentPath && !breadcrumb.isHideOnBreadcrumb;
      });

      if (found) {
        breadcrumbs.push(found);
      }

      return;
    }

    const separator = pathList[index - 1] === '/' ? '' : '/';
    const curentPath = pathList[index - 1] + separator + item;
    pathList.push(curentPath);

    const found = breadcrumbConfig.find(breadcrumb => {
      return breadcrumb.path === curentPath && !breadcrumb.isHideOnBreadcrumb;
    });

    if (found) {
      breadcrumbs.push(found);
    }
  });

  return breadcrumbs;
};

/**
 * Convert permissions to menus
 */
export const convertPermissionsToMenus = (permissions, breadcrumbConfig) => {
  if (
    !(Array.isArray(permissions) && permissions.length) ||
    !(Array.isArray(breadcrumbConfig) && breadcrumbConfig.length)
  ) {
    return [];
  }

  const menuList = breadcrumbConfig.filter(item => {
    if (item.isHideOnSidebar) {
      return false;
    }

    const menuItem = permissions.find(
      permission => permission.moduleId === item.moduleId
    );

    return menuItem;
  });

  return removeDuplicate(menuList, 'moduleId');
};

/**
 * Get object in array by value
 */
export const getObjectByValue = (val, list) => {
  if (!(Array.isArray(list) && list.length)) {
    return {};
  }

  const found = list.find(item => item.value === val);

  return found;
};

/**
 * Debounce function
 */
export const debounce = (func, wait, immediate) => {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

/**
 * Json parse
 * Use try catch to avoid errors
 */
export const jsonParse = data => {
  try {
    return JSON.parse(data);
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * Remove param from url
 */
export const removeParamFromUrl = (attribute, location, history) => {
  if (
    !attribute ||
    !location ||
    !(history && typeof history.push === 'function')
  ) {
    return;
  }

  const queryUrl = queryString.parse(location.search);
  const newQueryUrl = { ...queryUrl };

  if (queryUrl[attribute]) {
    delete newQueryUrl[attribute];
    history.push({ search: `?${queryString.stringify(newQueryUrl)}` });
  }
};

/**
 * Control url param by state
 * Push state to url
 * Or remove state from url if state is falsy
 */
export const controlUrlParamByState = (state, attribute, location, history) => {
  if (
    !attribute ||
    !location ||
    !(history && typeof history.push === 'function')
  ) {
    return;
  }

  const queryUrl = queryString.parse(location.search);
  const newQueryUrl = { ...queryUrl };

  if (state && state !== queryUrl[attribute]) {
    // [attribute] is empty, push [attribute] to url
    newQueryUrl[attribute] = state;
    history.push({ search: `?${queryString.stringify(newQueryUrl)}` });
  } else if (queryUrl[attribute] && !state) {
    // [attribute] is not empty, remove [attribute] on url
    delete newQueryUrl[[attribute]];
    history.push({ search: `?${queryString.stringify(newQueryUrl)}` });
  } else {}
};

/**
 * Remove duplicate item in array
 */
export const removeDuplicate = (list, byAttribute = 'id') => {
  if (!(Array.isArray(list) && list.length)) {
    return [];
  }

  if (byAttribute) {
    return [...list].reduce((accumulator, current) => {
      if (
        !accumulator.some(sub => {
          return sub[byAttribute] === current[byAttribute];
        })
      ) {
        accumulator.push(current);
      }

      return accumulator;
    }, []);
  }

  if (byAttribute === '') {
    return [...list].reduce((accumulator, current) => {
      if (!accumulator.some(sub => sub === current)) {
        accumulator.push(current);
      }

      return accumulator;
    }, []);
  }

  return [];
};

/**
 * For directory tree
 * Re add expanded keys, because when you click to tree item, this tree item is collapsed or expanded
 *
 * Expect: Always expand item when click it
 */
export const reAddExpandedKeys = (keys, expandedKeys, callback) => {
  if (
    !(Array.isArray(keys) && keys.length) ||
    !Array.isArray(expandedKeys) ||
    typeof callback !== 'function'
  ) {
    return;
  }

  let newExpandedKeys = [...expandedKeys];

  keys.forEach(key => {
    const count = expandedKeys.reduce((accumulator, current) => {
      return current === key ? accumulator + 1 : accumulator;
    }, 0);

    if (count >= 0) {
      newExpandedKeys = [...newExpandedKeys.filter(item => item !== key), key];
    }
  });

  callback(newExpandedKeys);
};

/**
 * Find item and parents on tree
 */
export const findItemAndParentsOnTree = (list, key, byAttribute = 'id') => {
  let found = null;
  const allParents = [];
  const parentList = [];

  // When the item is found, return
  if (found) {
    return;
  }

  // Find item
  const findItem = (list, key) => {
    if (found) {
      return;
    }

    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const hasChildren = Array.isArray(item.children) && item.children.length;

      if (item[byAttribute] === key) {
        found = item;
        break;
      }

      if (hasChildren) {
        allParents.push(item);
        findItem(item.children, key);
      }
    }
  };

  findItem(list, key);

  // Find parent nested of found item
  if (found && Array.isArray(allParents)) {
    const reverseList = [...allParents].reverse();

    reverseList.reduce((accumulator, current) => {
      if (
        Array.isArray(current.children) &&
        current.children.some(child => child[byAttribute] === accumulator)
      ) {
        parentList.push(current);
        accumulator = current.key;
      }

      return accumulator;
    }, found.key);
  }

  return {
    item: found,
    parentList: Array.isArray(parentList) ? parentList.reverse() : []
  };
};

/**
 * Search items by keyword on tree
 *
 * "list" are the found items
 * "parentKeys" for expand row on tree
 */
export const searchItemsByKeywordOnTree = (list, keyword) => {
  const itemKeys = [];
  const parentKeys = [];

  // Remove duplicate in string array (string[]). Also remove null
  const customRemoveDuplicate = stringArr => {
    return [...stringArr].reduce((accumulator, current) => {
      if (!accumulator.some(sub => sub === current)) {
        current && accumulator.push(current);
      }

      return accumulator;
    }, []);
  };

  // Find item keys and parent keys
  const findItemKeys = (list, keyword) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const hasChildren = Array.isArray(item.children) && item.children.length;

      if (
        typeof keyword === 'string' &&
        typeof item.title === 'string' &&
        item.title
          .toString()
          .toLowerCase()
          .indexOf(keyword.toString().toLowerCase()) > -1
      ) {
        itemKeys.push(item.key);
      }

      if (hasChildren) {
        findItemKeys(item.children, keyword);
      }
    }
  };

  // Run findItemKeys
  findItemKeys(list, keyword);

  // Build parent keys
  // Example: Before itemKey = "id-5_id-21_id-3". After: parentKeys = ["id-5", "id-5_id-21"]
  const convertItemKeyToParentKeys = key => {
    if (!(typeof key === 'string' && key !== '')) {
      return;
    }

    const lastIndexOfUnderscore = key.lastIndexOf('_');
    const newKey = key.substring(0, lastIndexOfUnderscore);
    parentKeys.push(newKey);

    if (newKey.split('_').length >= 2) {
      return convertItemKeyToParentKeys(newKey);
    }
  };

  // Run convertItemKeyToParentKeys on each itemKey
  itemKeys.forEach(key => convertItemKeyToParentKeys(key));

  // Merge itemKeys and parentKeys, remove duplicate keys
  const mergeKeys = customRemoveDuplicate([...parentKeys, ...itemKeys]);

  // Remove item on tree if it does not contain mergeKeys
  const removeItemsNotContainKeys = list => {
    return list.map(item => {
      const hasChildren = Array.isArray(item.children) && item.children.length;

      if (hasChildren) {
        const children = item.children.filter(child =>
          mergeKeys.includes(child.key)
        );

        return {
          ...item,
          children: removeItemsNotContainKeys(children)
        };
      }

      return { ...item };
    });
  };

  // Remove items in first lever
  const list2 = [...list].filter(item => mergeKeys.includes(item.key));
  const newList = removeItemsNotContainKeys([...list2]);

  return {
    list: newList,
    parentKeys: customRemoveDuplicate([...parentKeys])
  };
};

/**
 * Get parent keys
 */
export const getParentKeys = (list, byAttribute = 'id') => {
  const parentKeys = [];

  const getKeys = list => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      if (item.children && item.children.length) {
        parentKeys.push(item[byAttribute]);
        getKeys(item.children);
      }
    }
  };

  getKeys(list);

  return parentKeys;
};

/**
 * Convert estimated time to minute
 * For send data to server
 * Working hour: 1 week = 5 days, 1 day = 8 hours, 1 hour = 60 minutes
 *
 * Example - Before: 1w 1.2h 5d 2.5h 3.2m 8h
 * Example - After: 5505 (minutes)
 */
export const convertEstimatedTimeToMinutes = timeString => {
  if (typeof timeString === 'string') {
    timeString = timeString.trim();
  }

  if (!timeString || !new RegExp(ESTIMATED_TIME_PATTERN).test(timeString)) {
    return 0;
  }

  let estimatedTime = timeString;

  if (/^(([0-9]\d*(\.\d+)?))$/.test(timeString)) {
    estimatedTime = `${+timeString}d`;
  }

  const timeArr = estimatedTime.split(' ').filter(item => item);
  let minute = 0;

  timeArr.forEach(item => {
    const num = +item.match(/\d+(\.\d+)?/g); // Get float number in string

    if (item.includes('w')) {
      minute += num * 5 * 8 * 60;
    }

    if (item.includes('d')) {
      minute += num * 8 * 60;
    }

    if (item.includes('h')) {
      minute += num * 60;
    }

    if (item.includes('m')) {
      minute += Math.round(num);
    }
  });

  return Math.round(minute);
};

/**
 * Convert minutes to short time
 * For view time on browser
 *
 * Example - Before: 630 (minutes)
 * Example - After: 1d 3h 30m
 */
export const convertMinutesToShortTime = minutes => {
  if (!minutes || !/^[0-9]*$/.test(minutes)) {
    return '0d';
  }

  let timeString = '';
  const d = minutes / (60 * 8);
  const h = (d - Math.floor(d)) * 8;
  const m = (h - Math.floor(h)) * 60;

  const timeArr = [
    { label: 'd', value: d },
    { label: 'h', value: h }
  ];

  if (m === 15 || m === 30 || m === 45) {
    // If minute is 15, 30 or 45, convert to 0.25h, 0.5h or 0.75h
    timeString = timeArr
      .filter(item => {
        if (item.label === 'h') {
          return item;
        }
        return item.value >= 1;
      })
      .map(item => {
        if (item.label === 'h') {
          return `${item.value}${item.label}`;
        }
        return `${Math.floor(item.value)}${item.label}`;
      })
      .join(' ');
  } else {
    timeArr.push({ label: 'm', value: m });
    timeString = timeArr
      .filter(item => item.value >= 1)
      .map(item => `${Math.floor(item.value)}${item.label}`)
      .join(' ');
  }

  return timeString;
};

/**
 * Check different between two list
 * Check list: length of two list, values, order
 */
export const checkDifferentTwoList = (
  originList,
  currentList,
  attributes,
  sortBy
) => {
  if (!(Array.isArray(attributes) && attributes.length)) {
    throw new Error('There are no attributes to compare');
  }

  if (!Array.isArray(originList) || !Array.isArray(currentList)) {
    return true;
  }

  if (originList.length !== currentList.length) {
    return true;
  }

  // Get new list
  const getNewList = list => {
    let newList = [];

    newList = list.map(item => {
      const obj = {};

      attributes.forEach(attr => {
        obj[attr] = item[attr];
      });

      return obj;
    });

    if (sortBy) {
      newList = [...newList].sort((a, b) => {
        if (+a[sortBy] > +b[sortBy]) {
          return 1;
        }
        if (+a[sortBy] < +b[sortBy]) {
          return -1;
        }
        return 0;
      });
    }

    return newList;
  };

  const newOriginList = getNewList(originList);
  const newCurrentList = getNewList(currentList);

  return JSON.stringify(newOriginList) !== JSON.stringify(newCurrentList);
};

/**
 * On click to row and then select this row
 * Same click to checkbox on row
 */
export const onClickRow = (
  record,
  selectedRecords,
  callback,
  rowKey = 'id'
) => {
  if (!(record && record[rowKey]) || !Array.isArray(selectedRecords)) {
    return;
  }

  try {
    let selectedRows = [];

    if (
      selectedRecords.length &&
      selectedRecords.some(item => item[rowKey] === record[rowKey])
    ) {
      selectedRows = [...selectedRecords].filter(
        item => item[rowKey] !== record[rowKey]
      );
    } else {
      selectedRows = [...selectedRecords, record];
    }

    const selectedRowKeys = selectedRows.map(item => item[rowKey]);

    callback(selectedRowKeys, selectedRows);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get diff between dates
 */
export const getDiffBetweenDates = (
  start,
  end,
  unit = 'days',
  format = SERVER_DATE_FORMAT
) => {
  if (!(start && moment(start).isValid() && end && moment(end).isValid())) {
    return;
  }

  const startDate = moment(end, format).clone().endOf('day');
  const endDate = moment(start, format).clone().endOf('day');

  return startDate.diff(endDate, unit);
};

/**
 * Handle before submit form
 * Scroll to first error field
 */
export const handleBeforeSubmitForm = form => {
  const fieldsError = form.getFieldsError();

  if (Array.isArray(fieldsError) && fieldsError.length) {
    const firstErrorField = fieldsError.find(item => {
      return Array.isArray(item.errors) && item.errors.length;
    });

    if (firstErrorField) {
      const fieldName = firstErrorField.name[0];
      form.scrollToField(fieldName);

      return;
    }
  }

  form.submit();
};

export const getFileExtensionImage = (filename = '') => {
  const ext = filename.split('.')?.pop();
  const extImage = ['apng', 'avif', '.gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'bmp', 'ico', 'cur', 'tif', 'tiff'];
  const extExcel = ['xla', 'xlam', 'xll', 'xlm', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx'];
  const extWord = ['doc', 'docm', 'docx', 'dot', 'dotx'];
  const extPowerpoint = ['pot', 'potm', 'potx', 'ppam', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx'];
  const extPdf = ['pdf'];
  if (extImage.includes(ext)) {
    return faFileImage;
  }
  if (extExcel.includes(ext)) {
    return faFileExcel;
  }
  if (extWord.includes(ext)) {
    return faFileWord;
  }
  if (extPowerpoint.includes(ext)) {
    return faFilePowerpoint;
  }
  if (extPdf.includes(ext)) {
    return faFilePdf;
  } else {
    return faFile;
  }
};

export const wordCount = (val) => {
  const wom = val.match(/\S+/g);
  return {
      charactersNoSpaces: val.replace(/\s+/g, '').length,
      characters: val.length,
      words: wom ? wom.length : 0,
      lines: val.split(/\r*\n/).length
  };
}

export const commonDownloadFile = (response = null, payload = null) => {
  if (response !== null && payload !== null) {
    fileDownload(response.data, `${payload.nameDisplay}.${payload.file.split('.')?.pop()}`);
  }
};