import React from 'react';
import { EnFlag, FrFlag, JaFlag, VnFlag } from '../assets/svg-icons';

export * from './breadcrumb-config';
export * from './endpoints';
export * from './master-data';
export * from './notification';
export * from './groups';
export * from './user-roles';

/**
 * Config localStorage, sessionStorage variables
 */
export const LS_SESSION_TOKEN = 'token';
export const LS_SESSION_USERNAME = 'username';
export const LS_SESSION_OTP = 'isRequiredOTP';
export const LS_ACTIVED_GROUP_ID = 'activedGroupId';
export const LS_USER_INFO = 'user';
export const LS_LANGUAGE = 'lang';
export const LS_LATEST_PROJECT = 'latestProject';
export const LS_LATEST_RELEASE = 'latestRelease';
export const SS_PREVIOUS_URL = 'previousUrl';
export const SS_DEFECT_PROJECT_BY_PROJECT_ID = 'defectProjectByProjectId';
export const SS_FIELD_LIST_BY_PROJECT_ID = 'fieldListByProjectId';
export const LS_SELECTED_TLIP_EVENT = 'selectedTlipEvent';
export const LS_SELECTED_MONTH_EVENT = 'selectedMonthEvent';
export const LS_SELECTED_DATE_EVENT = 'selectedDateEvent';
export const LS_DOCUMENT_INFO = 'document';
export const LS_DOC_TYPE = 'docType';

/**
 * Pagination default
 */
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_PAGE_SIZE_SUBLIST = 5;

/**
 * Http status code list
 */
export const HTTP_STATUS_CODES = [
  { label: 'Bad Request', value: 400 },
  { label: 'Unauthorized', value: 401 },
  { label: 'Payment Required', value: 402 },
  { label: 'Forbidden', value: 403 },
  { label: 'Not Found', value: 404 },
  { label: 'Internal Server Error', value: 500 }
];

/**
 * Language list
 */
export const LANGUAGES = [
  { label: 'common.english', value: 'en', flag: <EnFlag /> },
  { label: 'common.french', value: 'fr', flag: <FrFlag /> },
  { label: 'common.japanese', value: 'ja', flag: <JaFlag /> },
  { label: 'common.vietnamese', value: 'vi', flag: <VnFlag /> }
];

/**
 * Role template type list
 * Role template value is type of role template (File "../role_template.json")
 */
export const ROLE_TENANT_ADMIN = -1;
export const ROLE_ADMINISTRATION = 1;
export const ROLE_PROJECT_APPS = 2;
export const ROLE_GLOBAL_REPOSITORY = 3;
export const ROLE_DASHBOARD = 4;
export const ROLE_DEFECT = 5;
export const ROLE_PROJECT_ADMIN = 6;

/**
 * Permission list
 */
export const P_READ = 1; // Permission Read
export const P_CREATE_AND_EDIT = 2; // Permission Create and Edit
export const P_DELETE = 3; // Permission Delete
export const PERMISSION_LIST = [
  { value: P_READ, label: 'role.permissionRead' },
  { value: P_CREATE_AND_EDIT, label: 'role.permissionCreateAndEdit' },
  { value: P_DELETE, label: 'role.permissionDelete' }
];

/**
 * Max length (for validatiton)
 */
export const MAX_LENGTH_TITLE = 128;
export const MAX_LENGTH_CUSTOM_FIELD_TEXT = 1024;
export const MAX_LENGTH_CUSTOM_FIELD_LONG_TEXT = 32000;

/**
 * List of number record in one page
 */
export const PAGE_SIZES = [10, 20, 50, 100];

/**
 * Number record in one page
 */
export const PAGE_SIZE = PAGE_SIZES[0];
export const PAGE_SIZE_5 = 5;

/**
 * Default sort value
 */
export const SORT_TYPE_DESC = 'DESC';
export const SORT_TYPE_ASC = 'ASC';
export const DEFAULT_SORT = ['updatedAt', SORT_TYPE_DESC];
export const SORT_BY_EXECUTED_DATE_DESC = ['executedDate', SORT_TYPE_DESC];
export const JIRA_SORT = ['updated', SORT_TYPE_DESC];
export const SORT_BY_ORDER_ID_DESC = ['orderId', SORT_TYPE_DESC];
export const SORT_BY_CREATED_AT_ASC = ['createdAt', SORT_TYPE_ASC];

/**
 * Patterns
 */
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;
export const PASSWORD_PATTERN =
/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#\$%\^&\*\(\)\.,\-\_\+\/\?><;\':\"])(?=.{8,})/gm; // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*])(?=.{8,})/;
export const URL_PATTERN =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\-.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/;
export const ESTIMATED_TIME_PATTERN = /^(([0-9])*(\.)*([0-9])+([wdhm])(\s)*)+$/; // Example: 1w 1.2h 5d 2.5h 32m 8h
export const NUMBER_PATTERN = /^-?[0-9]\d*(\.\d+)?$/;
// export const ORDER_BY_FOR_AQL_PATTERN =
//   /((ORDER BY )([A-Za-z0-9_]+\.*)+(\|)(DESC|ASC))+/g; // Example: ORDER BY cycle.name|DESC, example: ORDER BY id|ASC

/**
 * Date format
 */
export const SHORT_DATE_FORMAT = 'MM/DD/YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * License period list
 */
export const LICENSE_PERIODS = [{ value: 3 }, { value: 6 }, { value: 12 }];

/**
 * Jira authentication mode list
 */
export const JIRA_AUTH_MODE_O_AUTH = 1;
export const JIRA_AUTH_MODE_COOKIE_BASED_AUTH = 2;
export const JIRA_AUTH_MODES = [
  { value: JIRA_AUTH_MODE_O_AUTH, label: 'OAuth (1.0a)' }
  // { value: JIRA_AUTH_MODE_COOKIE_BASED_AUTH, label: 'Cookie-based auth' }
];

/**
 * Internal defect system
 */
export const INTERNAL_DEFECT_SYSTEM_ID = -1;
export const INTERNAL_DEFECT_SYSTEM = {
  value: INTERNAL_DEFECT_SYSTEM_ID,
  label: 'project.internalDefect'
};

/**
 * Project type list
 */
export const PROJECT_TYPE_NORMAL = '1';
export const PROJECT_TYPES = [
  { value: PROJECT_TYPE_NORMAL, label: 'project.typeNormal' },
  { value: '2', label: 'project.typeRestricted' },
  { value: '3', label: 'project.typeIsolated' }
];

/**
 * Priority list
 */
export const PRIORITY_MEDIUM = '4';
export const PRIORITIES = [
  { value: '1', label: 'Blocker' },
  { value: '2', label: 'Highest' },
  { value: '3', label: 'High' },
  { value: PRIORITY_MEDIUM, label: 'Medium' },
  { value: '5', label: 'Low' }
];

/**
 * Priority list
 */

export const JIRA_PRIORITY_MEDIUM = 'Medium';
export const JIRA_PRIORITIES = [
  { value: 'Highest', label: 'Highest' },
  { value: 'High', label: 'High' },
  { value: JIRA_PRIORITY_MEDIUM, label: 'Medium' },
  { value: 'Low', label: 'Low' },
  { value: 'Lowest', label: 'Lowest' }
];

/**
 * Test result status
 */
export const TEST_RESULT_STATUS_NOT_EXECUTED = 'Not Executed';
export const TEST_RESULT_STATUS_PASS = 'Pass';
export const TEST_RESULT_STATUS_FAIL = 'Fail';
export const TEST_RESULT_STATUS_WIP = 'WIP';
export const TEST_RESULT_STATUS_BLOCKED = 'Blocked';
export const TEST_RESULT_STATUS_LIST = [
  {
    value: TEST_RESULT_STATUS_NOT_EXECUTED,
    label: 'Not Executed',
    className: 'not-executed'
  },
  {
    value: TEST_RESULT_STATUS_PASS,
    label: 'Pass',
    className: 'pass-status'
  },
  {
    value: TEST_RESULT_STATUS_FAIL,
    label: 'Fail',
    className: 'fail-status'
  },
  {
    value: TEST_RESULT_STATUS_WIP,
    label: 'WIP',
    className: 'wip-status'
  },
  {
    value: TEST_RESULT_STATUS_BLOCKED,
    label: 'Blocked',
    className: 'blocked-status'
  }
];

/**
 * Defect statuses
 */
export const DEFECT_STATUS_NEW = 1;
export const DEFECT_STATUSES = [
  { value: DEFECT_STATUS_NEW, label: 'NEW' },
  { value: 2, label: 'ASSIGNED' },
  { value: 3, label: 'FIXED' },
  { value: 4, label: 'REJECTED' },
  { value: 5, label: 'VERIFIED' },
  { value: 6, label: 'REOPENED' },
  { value: 7, label: 'CLOSED' }
];

/**
 * Defect severities
 */
export const DEFECT_SEVERITIES = [
  { value: '1', label: 'Fatal' },
  { value: '2', label: 'Major' },
  { value: '3', label: 'Minor' },
  { value: '4', label: 'Trivial' },
  { value: '5', label: 'Suggestion' },
  { value: '6', label: 'Blocker' }
];

/**
 * OS list
 */
export const OS_LIST = [
  { value: '-1', label: 'common.all', isChangedLanguage: true },
  { value: '1', label: 'Windows' },
  { value: '2', label: 'Mac OS' },
  { value: '3', label: 'Linux' },
  { value: '4', label: 'common.others', isChangedLanguage: true }
];

/**
 * Hardware list
 */
export const HARDWARE_LIST = [
  { value: '-1', label: 'common.all', isChangedLanguage: true },
  { value: '2', label: 'PC' },
  { value: '3', label: 'Macintosh' },
  { value: '4', label: 'common.others', isChangedLanguage: true }
];

/**
 * Test result Most Failed  Test Cases
 */
export const TEST_RESULT_MOST_FAILED_TESTCASE = 'mostFailed';
export const TEST_RESULT_MOST_FAILED_LIST = [
  {
    value: TEST_RESULT_MOST_FAILED_TESTCASE,
    label: 'mostFailed'
  }
];

/**
 * Test result Most Executed Test Cases
 */
export const TEST_RESULT_MOST_EXECUTED_TESTCASE = 'mostExecuted';
export const TEST_RESULT_MOST_EXECUTED_LIST = [
  {
    value: TEST_RESULT_MOST_EXECUTED_TESTCASE,
    label: 'mostExecuted'
  }
];

/**
 * Test result plan vs executed
 */
export const TEST_RESULT_PLAN = 'Plan';
export const TEST_RESULT_EXECUTED = 'Executed';
export const TEST_RESULT_LIST = [
  { value: TEST_RESULT_PLAN, label: 'Plan' },
  { value: TEST_RESULT_EXECUTED, label: 'Executed' }
];

/**
 * Test result chart by user
 */
export const TEST_RESULT_USER_SCHEDULE = 'Schedule';
export const TEST_RESULT_USER_EXECUTED = 'Executed';
export const TEST_RESULT_USER_LIST = [
  { value: TEST_RESULT_USER_SCHEDULE, label: 'Schedule' },
  { value: TEST_RESULT_USER_EXECUTED, label: 'Executed' }
];

/**
 * Tenant status
 */
export const TENANT_STATUS_ACTIVE = 1;
export const TENANT_STATUS_INACTIVE = 0;
export const TENANT_STATUSES = [
  { value: TENANT_STATUS_ACTIVE, label: 'tenant.statusActive' },
  { value: TENANT_STATUS_INACTIVE, label: 'tenant.statusInactive' }
];

/**
 * Group status
 */
export const GROUP_STATUS_ACTIVE = 1;
export const GROUP_STATUS_INACTIVE = 0;
export const GROUP_STATUSES = [
  { value: GROUP_STATUS_ACTIVE, label: 'group.statusActive' },
  { value: GROUP_STATUS_INACTIVE, label: 'group.statusInactive' }
];

/**
 * Release status
 */
export const RELEASE_STATUS_YES = 1;
export const RELEASE_STATUS_NO = 0;
export const RELEASE_STATUSES = [
  { value: RELEASE_STATUS_YES, label: 'common.yes' },
  { value: RELEASE_STATUS_NO, label: 'common.no' }
];

/**
 * Cycle statuses
 */
export const CYCLE_STATUS_YES = 1;
export const CYCLE_STATUS_NO = 0;

/**
 * View and view type list
 */
export const VIEW_FOLDER = 'folder';
export const VIEW_SEARCH = 'search';
export const VIEW_SEARCH_BASIC = 'basic';
export const VIEW_SEARCH_ADVANCED = 'advanced';

export const VIEW_TYPE_LIST = 'list';
export const VIEW_TYPE_DETAIL = 'detail';

/**
 * Tree type list
 */
// For requirement and test repository tree
export const TREE_TYPE_ALL = 'ALL';
export const TREE_TYPE_PROJECT = 'PROJECT';
export const TREE_TYPE_RELEASE = 'RELEASE';

// For test planning tree
export const TREE_TYPE_CYCLE = 'CYCLE';
export const TREE_TYPE_PHASE = 'PHASE';

/**
 * Test case custom field data types
 */
export const DATA_TYPE_TEXT = 1;
export const DATA_TYPE_LONG_TEXT = 2;
export const DATA_TYPE_PICK_LIST = 3;
export const DATA_TYPE_CHECKBOX = 4;
export const DATA_TYPE_DATE = 5;
export const DATA_TYPE_NUMBER = 6;
export const DATA_TYPE_RICH_TEXT = 7;

export const TEST_CASE_FIELD_DATA_TYPES = [
  {
    value: DATA_TYPE_TEXT,
    label: 'testCaseCustomFields.dataTypeText1024'
  },
  {
    value: DATA_TYPE_LONG_TEXT,
    label: 'testCaseCustomFields.dataTypeLongText32000'
  },
  {
    value: DATA_TYPE_PICK_LIST,
    label: 'testCaseCustomFields.dataTypePicklist'
  },
  {
    value: DATA_TYPE_CHECKBOX,
    label: 'testCaseCustomFields.dataTypeCheckbox'
  },
  {
    value: DATA_TYPE_DATE,
    label: 'testCaseCustomFields.dataTypeDate'
  },
  {
    value: DATA_TYPE_NUMBER,
    label: 'testCaseCustomFields.dataTypeNumber'
  },
  {
    value: DATA_TYPE_RICH_TEXT,
    label: 'testCaseCustomFields.dataTypeRichText'
  }
];

/**
 * Automation type list
 */
export const AUTOMATION_TYPE_SCRIPT = 1;
export const AUTOMATION_TYPE_SUITE = 0;

/**
 * Automation status list
 */
export const AUTOMATION_STATUS_WAITING = 'WAITING';
export const AUTOMATION_STATUS_RUNNING = 'RUNNING';
export const AUTOMATION_STATUS_STOPPING = 'STOPPING';
export const AUTOMATION_STATUS_ERROR = 'ERROR';
export const AUTOMATION_STATUS_COMPLETED = 'COMPLETED';

/**
 * Chart type list
 */
export const CHARTS = [
  {
    title: 'report.basicCharts',
    list: [
      {
        value: 'bar',
        label: 'report.barChart',
        description: 'report.barChartDesctiption',
        picture: '/images/default-chart-bar.png'
      },
      {
        value: 'line',
        label: 'report.lineChart',
        description: 'report.lineChartDesctiption',
        picture: '/images/default-chart-line.png'
      },
      {
        value: 'doughnut',
        label: 'report.doughnutChart',
        description: 'report.doughnutChartDesctiption',
        picture: '/images/default-chart-doughnut.png'
      },
      {
        value: 'pie',
        label: 'report.pieChart',
        description: 'report.pieChartDesctiption',
        picture: '/images/default-chart-pie.png'
      },
      {
        value: 'polarArea',
        label: 'report.polarAreaChart',
        description: 'report.polarAreaChartDesctiption',
        picture: '/images/default-chart-polar-area.png'
      },
      {
        value: 'radar',
        label: 'report.radarChart',
        description: 'report.radarChartDesctiption',
        picture: '/images/default-chart-radar.png'
      }
    ]
  },
  {
    title: 'report.timeSeriesCharts',
    list: [
      {
        value: 'timeSeries',
        label: 'report.timeSeriesChart',
        description: 'report.timeSeriesChartDesctiption',
        picture: '/images/default-chart-time-series.png'
      }
    ]
  }
];

/**
 * Other
 */
 export const DOC_TYPE_RELATED = 'related-document';
 export const DOC_TYPE_CRS_ACTIVITY = 'src-activity';

/**
 * Chart condition list
 */
export const CHART_CONDITIONS = [
  { value: '~', label: '~', rsqlValue: '==' },
  { value: '>', label: '>', rsqlValue: '>' },
  { value: '<', label: '<', rsqlValue: '<' },
  { value: '=', label: '=', rsqlValue: '==' },
  { value: '!=', label: '!=', rsqlValue: '!=' }
];

/**
 * CSS class
 */
export const CSS_SIDE_BAR = "line-menu line-normal";
export const CSS_SELECTED_TAB_CONTENT = "tab-content selected";
export const CSS_TAB_CONTENT = "tab-content";
export const CSS_REPEAT_DATE = "repeat-date";
export const CSS_LOGOUT_MODAL = "logout-modal";

/**
 * Language
 */
export const LANG_COMMON_ERROR = "tlip:common.error";
export const LANG_LOGIN_CURRENT_PWD = "login.currentPassword";
export const LANG_MSG_REQUIRED = "message.required";
export const LANG_LOGIN_NEW_PWD = "login.newPassword";
export const LANG_LOGIN_PWD = "login.password";
export const LANG_LOGIN_CONFIRM_NEW_PWD = "login.confirmNewPassword";
export const LANG_CONTENT_ERR_TYPE_FILE = "content.infoUpload.errorTypeFile";
export const LANG_CONTENT_ERR_SIZE_FILE = "content.infoUpload.errorSizeFile";
export const LANG_CONTENT_SAVE = "content.save";
export const LANG_CONTENT_LIMIT_3000 = "message.contentWrongLimitSize3000";
export const LANG_MSG_INVALID_PAYLOAD = "Invalid Payload";
export const LANG_MSG_INVALID_DATE = "Invalid date";
export const LANG_CREATE_CUSTOMER = "customer.createCustomer";
export const LANG_CUSTOMER_INPUT_LIMIT_100 = "customer.msgInputLimit100Character";
export const LANG_CUSTOMER_INPUT_REQUIRED = "customer.msgInputRequired";
export const LANG_CUSTOMER_CREATED_BY = "customer.createdby";
export const LANG_MSG_REQUIRED_FIELD = "message.requiredField";
export const LANG_EMAIL_TOOLTIP_LIMIT_20 = "emailInfo.tooltip.limit8to20Character";
export const LANG_COMMON_UPDATE = "common.update";
export const LANG_COMMON_CANCEL = "common.cancel";
export const LANG_MSG_SUCCESSFULLY = "message.successfully";
export const LANG_MSG_USERNAME_WRONG_FORMAT = "message.usernameWrongFormat";
export const LANG_MSG_CREATE_AT_DATE = "eventManage.creating.atDate";
export const LANG_MSG_EXISTED_TITLE = "message.titleExisted";
export const LANG_FORM_USERNAME = "form.username";

/**
 * FORMAT DATE TIME
 */
export const FORMAT_DD_MM_YYYY = "DD-MM-YYYY";
export const FORMAT_DD_MM_YYYY_HH_MM = "DD-MM-YYYY HH:mm";
export const FORMAT_YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm';


