import { action, thunk } from 'easy-peasy';
import { Http, handleError } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
    listEmail: {
        data: []
    },
    listGroup: {
        data: []
    },
    loadingList: false,
    loadingListEmail: false,
    loadingSave: false,

    /** Action */
    setListEmail: action((state, payload) => {
        state.listEmail = { ...payload };
    }),
    setLoadingList: action((state, payload) => {
        state.loadingList = payload;
    }),
    setLoadingListEmail: action((state, payload) => {
        state.loadingListEmail = payload;
    }),
    setLoadingSave: action((state, payload) => {
        state.loadingSave = payload;
    }),
    setListGroup: action((state, payload) => {
        state.listGroup = { ...payload };
    }),
    getListEmail: thunk(async (action, payload = {}) => {
        try {
            action.setLoadingListEmail(true);
            const url = `${ENDPOINTS.EMAIL_INFO}`;
            const { data } = await Http.get(url, {});
            if (data) {
                action.setListEmail({
                    data: data?.data.groupMailItems ?? []
                });
            }
        } catch (err) {
            action.setListEmail({
                data: []
            });
        } finally {
            action.setLoadingListEmail(false);
        }
    }),
    updateEmail: thunk(async (action, payload) => {
        try {
            if (!(payload !== null && typeof payload === 'object')) {
                throw new Error('Invalid Payload');
            }

            action.setLoadingSave(true);
            const url = `${ENDPOINTS.EMAIL_INFO}`;

            const res = await Http.put(url, payload);

            return res;
        } catch (err) {
            handleError(err);
        } finally {
            action.setLoadingSave(false);
        }
    }),

    createEmail: thunk(async (action, payload) => {
        try {
            if (!(payload !== null && typeof payload === 'object')) {
                throw new Error('Invalid Payload');
            }

            action.setLoadingSave(true);
            const url = `${ENDPOINTS.EMAIL_INFO}`;

            const res = await Http.post(url, payload);

            return res;
        } catch (err) {
            handleError(err);
        } finally {
            action.setLoadingSave(false);
        }
    }),
    getListGroup: thunk(async (action, payload = {}) => {
        try {
            action.setLoadingList(true);
            const url = `${ENDPOINTS.GROUP_INFO}`;
            const { data } = await Http.get(url, {});
            if (data) {
                action.setListGroup({
                    data: data?.data ?? []
                });
            action.setLoadingList(false);

            }
        } catch (err) {
            action.setListGroup({
                data: []
            });
        } finally {
        }
    }),
};
