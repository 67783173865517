import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';
import viVN from 'antd/lib/locale/vi_VN';
import { useStoreActions, useStoreState } from 'easy-peasy';
// Language
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/vi';
import React, { lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { reactLocalStorage } from '../common';
import { LS_LANGUAGE } from '../constants';
import { ENDPOINTS } from '../constants';

const Login = lazy(() => import('./login'));
const OTPLogin = lazy(() => import('./login-otp'));
const ForgotPassword = lazy(() => import('./forgot-password'));
const ChangePassword = lazy(() => import('./change-password'));
const Customer = lazy(() => import('./customer'));
const CustomerDetail = lazy(() => import('./customer/customer-detail'));
const CustomerCreate = lazy(() => import('./customer/customer-create'));
const CustomerEdit = lazy(() => import('./customer/customer-edit'));
const User = lazy(() => import('./user'));
const Permission = lazy(() => import('./permission'));
const Content = lazy(() => import('./content'));
const EmailInfo = lazy(() => import('./email-info'));
const Event = lazy(() => import('./event'));
const Document = lazy(() => import('./document'));
// const DocumentDetail = lazy(() => import('./document/document-detail'));
// const DocumentCreate = lazy(() => import('./document/document-create'));
// const DocumentEdit = lazy(() => import('./document/document-edit'));
const MasterData = lazy(() => import('./master-data'));
const MasterDataDetail = lazy(() => import('./master-data/master-data-detail'));
const Error403 = lazy(() => import('./error-403'));
const Error404 = lazy(() => import('./error-404'));
const UserDetail = lazy(() => import('./user-detail'));

export default () => {
  const languageLocal = reactLocalStorage.get(LS_LANGUAGE);

  // For global language action
  const setGlobalLanguage = useStoreActions(
    action => action.global.setGlobalLanguage
  );
  const globalLanguage = useStoreState(state => state.global.globalLanguage);

  // Other state
  const [locale, setLocale] = useState(null);

  /**
   * Set global language by "lang" in localStorage
   */
  useEffect(() => {
    setGlobalLanguage(languageLocal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Watching change of globalLanguage in STORE
   * Set language to localStorage
   * Language for i18next, for ant design locate, for moment
   */
  useEffect(() => {
    if (!globalLanguage) {
      return;
    }

    if (globalLanguage === 'en') {
      setLocale(enUS);
      moment.locale('en');
    } else if (globalLanguage === 'ja') {
      setLocale(jaJP);
      moment.locale('ja');
    } else if (globalLanguage === 'vi') {
      setLocale(viVN);
      moment.locale('vi');
    } else {}

    i18next.changeLanguage(globalLanguage);
    reactLocalStorage.set(LS_LANGUAGE, globalLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLanguage]);

  return (
    <ConfigProvider locale={locale}>
      <Switch>
        <Route exact path="/" component={Customer} />

        <Route path="/login" component={Login} />

        <Route path="/otp-verify" component={OTPLogin} />

        <Route path="/forgot-password" component={ForgotPassword} />

        <Route path="/change-password" component={ChangePassword} />

        <Route path="/user-detail" component={UserDetail} />

        <Route path={ENDPOINTS.CUSTOMER} render={({ match: { url } }) => (
          <>
            <Route path={`${url}/`} component={Customer} exact />
            <Route path={`${url}/create`} component={CustomerCreate} exact/>
            <Route path={`${url}/edit/:idCustomer`} component={CustomerEdit} exact/>
            <Route path={`${url}/detail/:idCustomer`} component={CustomerDetail} />
          </>
        )} />

        <Route path="/user" component={User} />

        <Route path="/permission" component={Permission} />

        <Route path="/content" component={Content} />

        <Route path="/email-info" component={EmailInfo} />

        <Route path="/event" component={Event} />

        <Route path="/document" component={Document} />

        {/* <Route path="/document"
          render={({ match: { url } }) => (
            <>
              <Route exact path={`${url}/`} component={Document} />
              <Route path={`${url}/detail`} component={DocumentDetail} />
              <Route exact path={`${url}/create`} component={DocumentCreate} />
              <Route exact path={`${url}/edit`} component={DocumentEdit} />
            </>
          )} /> */}

        <Route path={ENDPOINTS.MASTER_DATA}
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={MasterData} exact />
              <Route path={`${url}/:typeMasterData`} component={MasterDataDetail} />
            </>
          )} />

        <Route path="/403" component={Error403} />

        <Route path="/404" component={Error404} />

        <Redirect to="/404" />
      </Switch>
    </ConfigProvider>
  );
};
