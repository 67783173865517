/**
 * Endpoint declaration
 */
export const ENDPOINTS = {
  API_URL_BASE: process.env.REACT_APP_API_ENDPOINT,

  // For administrator
  LOGIN: '/accounts/admin/login',
  LOGIN_OTP: '/accounts/otp/login',
  EMAIL: '/emails',
  TENANT: '/tenants',
  REPORTS: '/reports',
  REPORT_DOWNLOAD: '/reports/download',
  FORGOT_PASSWORD: '/accounts/admin/forgot-password',
  RESET_PASSWORD: '/accounts/change-password',
  CHANGE_PASSWORD: '/change-password',
  USERS: '/admin/users',
  USER_DETAIL: '/users/me',
  GROUP_INFO: '/groups',
  ROLE: '/manager/roles',
  EVENT: '/events',
  EVENT_PAGING: '/events/pageable',
  CONFIG_CUSTOM_FIELD: '/manager/config/custom-field',
  PREFERENCE: '/manager/preference',
  USER: '/manager/user',
  USER_INFO: '/manager/user/myuser/info',
  USER_REQUEST_LICENSE: '/manager/user/requestLicense',
  GROUP: '/manager/group',
  PROJECT: '/manager/project',
  PROJECT_SUMMARY: '/manager/project/summary',
  PROJECT_USER: '/manager/project-user',
  PROJECT_GROUP: '/manager/project-group',
  DEFECT_ADMIN_GROUP: '/manager/group',
  MASTER_DATA: '/admin/data-master',
  DOCUMENT: '/document',
  CUSTOMER: '/customer',
  CUSTOMER_DETAIL: '/tenants',
  EMERGENCY_CONTACT: '/admin/content/emergency-contact',
  UPLOAD_IMAGE: '/admin/content/upload/plot-map',
  RECRUITMENT_INFO: '/admin/content/recruitment-info',
  CONTENT_SRC_ACTIVITY: '/admin/content/src-activity',
  EMAIL_INFO: '/admin/group-mail',
  CUSTOM_LIST: '/admin/tenants',
  GENERAL_EMERGENCY_CONTACT: '/general/emergency-contact',
  GENERAL_RECRUITMENT_INFO: '/general/recruitment-info',
  GENERAL_SRC_ACTIVITY: '/general/src-activity',
  GENERAL_PLOT_MAP: '/general/plot-map',


  // For project
  RELEASE: '/manager/release',
  REPOSITORY_FOLDER: '/manager/repository',
  TESTCASE: '/manager/testcase',
  TESTCASE_SEARCH_BY_QUERY: '/manager/testcase/search/by-query',
  TESTCASE_SEARCH: '/manager/search/testcase',
  TESTCASE_VERSION: '/manager/testcase-version',
  TESTCASE_UPDATE_VERSION: '/manager/testcase/update-version',
  TESTCASE_IMPORT_EXCEL: '/manager/import/testCase',
  TESTCASE_EXPORT: '/manager/export/testcase',
  TESTSTEP: '/manager/teststep',
  TESTSTEP_RESULT: '/manager/test-step-result',
  REQUIREMENT_FOLDER: '/manager/requirement-category',
  REQUIREMENT: '/manager/requirement',
  REQUIREMENT_SEARCH_BY_QUERY: '/manager/requirement/search/by-query',
  REQUIREMENT_TESTCASE_MAPPING: '/manager/requirement-testcase-mapping',
  REQUIREMENT_IMPORT_EXCEL: '/manager/import/requirement',
  REQUIREMENT_EXPORT: '/manager/export/requirement',
  TEST_PLAN: '/manager/test-plan',
  TEST_PLAN_PHASE: '/manager/test-plan/phase',
  TEST_PLAN_PHASE_STATUS: '/manager/test-plan/phase-status',
  TEST_PLAN_ASSIGN: '/manager/test-plan-assign/assign',
  TEST_SCHEDULE: '/manager/test-schedule',
  TEST_SCHEDULE_SYNC: '/manager/test-schedule/sync',
  DASHBOARD_TEST_EXECUTE: '/dashboard/test-execute',
  TEST_RESULT: '/manager/test-result',
  TEST_RESULT_BY_TESTCASE: '/manager/test-result/by-testcase',
  RUNABLE_SEND: '/manager/runable/send',
  DEFECT_TRACKING: '/manager/defect',
  DEFECT_TRACKING_SUMMARY: '/manager/defect/summary/all',
  DEFECT_TESTCASES: '/manager/defect-testcases',
  DEFECT_TESTCASES_LINK: '/manager/defect-testcases/link',
  REPORT_TEST_CASE_EXECUTION_PROGRESS:
    '/manager/report/test-case-execution-progress',
  REPORT_TREND_CHART_BY_STATUS: '/manager/report/trend-chart-by-status',
  REPORT_TREND_CHART_BY_USER: '/manager/report/trend-chart-by-user',
  REPORT_MOST_EXECUTED_TEST_CASES:
    '/manager/report/trend-chart-by-most-executed-testcase',
  REPORT_MOST_FAILED_TEST_CASES:
    '/manager/report/trend-chart-by-most-failed-testcase',
  REPORT_PLANNED_VS_EXECUTED: '/manager/report/trend-chart-by-most-plan-executed',
  AUTOMATION: '/manager/automation',
  AUTOMATION_STARTNOW: '/manager/automation/startnow',
  DEVICE: '/list-devices',
  DEVICE_CAPABILITIES: '/manager/device-capabilities',
  DASHBOARD: '/manager/dashboard',
  GADGET: '/manager/gadget',
  IMPORT_MAPPING: '/manager/import-mapping',
  IMPORT_FILE: '/manager/import/file',
  FILE_UPLOAD: '/manager/file/upload',
  SEARCH_GET_FIELD: '/manager/search/get-field',

  // Jira
  JIRA_INTEGRATION: '/integrate',
  JIRA_AUTH_URL: '/jira/auth-url',
  JIRA_ACCESS_TOKEN: '/jira/access-token',
  JIRA_ISSUE: '/jira/issue',
  JIRA_ISSUE_COMPONENT_LIST: '/jira/componentList',
  JIRA_ISSUE_USER_LIST: '/jira/userList',
  JIRA_ISSUE_VERSION_LIST: '/jira/versionList',
  JIRA_ISSUE_STATUS_LIST: '/jira/statusList',
  JIRA_ISSUE_STATUS: '/jira/issue/status',

  // Dashboard Chart
  DASHBOARD_CHART: '/dashboard/chart',
  DASHBOARD_CHART_FIELDS: '/dashboard/chart/fields',
  DASHBOARD_CHART_COLLECTION: '/dashboard/chart/collection',
  DASHBOARD_REPORT: '/dashboard/report',
  // document
  LS_ACTIVED_GROUP_ID: 'activedGroupDoc',
  LS_ACTIVED_GROUP_NAME: 'activedGroupDocName',
  DOCUMENT_DETAIL: '/reports/detail',
  DOCUMENT_CREATE: '/reports/upload-file',
  DOCUMENT_LIST: '/reports/admin',
  DOCUMENT_DELETE: '/reports',
  DOCUMENT_UPDATE: '/reports'
};
