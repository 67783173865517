import { action, thunk } from 'easy-peasy';
import { handleError, Http } from '../../common';
import { ENDPOINTS } from '../../constants';

export const model = {
  /** State */
  dataCustomList: {
    list: [],
    total: 0
  },

  listUsers : {
    list: [],
    total: 0
  },

  customer: {},

  loadingList: false,
  loadingListUser: false,
  loadingSave: false,
  loadingDetail: false,

  /** Action */
  setLoadingSave: action((state, payload) => {
    if (state?.loadingSave === undefined) {
      return;
    }
    state.loadingSave = payload;
  }),
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }
    state.loadingList = payload;
  }),
  setLoadingListUser: action((state, payload) => {
    if (state?.loadingListUser === undefined) {
      return;
    }
    state.loadingListUser = payload;
  }),
  setLoadingDetail: action((state, payload) => {
    if (state?.loadingDetail === undefined) {
      return;
    }
    state.loadingDetail = payload;
  }),
  setDataList: action((state, payload) => {
    state.dataCustomList = {...payload};
  }),
  setListUsers: action((state, payload) => {
    state.listUsers = {...payload};
  }),
  setDataCustomer: action((state, payload) => {
    state.customer = {...payload};
  }),


  getListCustomer: thunk(async (action, payload) => {
    try {
      action.setLoadingList(true);
      const url = `${ENDPOINTS.CUSTOM_LIST}`;
      const { data } = await Http.get(url, {
        params: {...payload}
      });
      if (data) {
        action.setDataList({
          list: data?.data?.list[0]?.tenants ?? [],
          total: data?.data?.total ?? 0
        });
      }
    } catch (err) {
      action.setDataList({
        list: [],
        total: 0
      });
    } finally{
      action.setLoadingList(false);
    }
  }),
  getListUserByCustomer: thunk(async (action, payload) => {
    try {
      action.setLoadingListUser(true);
      const url = `${ENDPOINTS.CUSTOM_LIST}/${payload.customerId}/users`;
      const { data } = await Http.get(url, {
        params: {...payload}
      });
      if (data) {
        action.setListUsers({
          list: data?.data?.list ?? [],
          total: data?.data?.total ?? 0
        });
      }
    } catch (err) {
      action.setListUsers({
        list: [],
        total: 0
      });
    } finally{
      action.setLoadingListUser(false);
    }
  }),
  getCustomer: thunk(async (action, payload) => {
    try {
      action.setLoadingDetail(true);
      const url = `${ENDPOINTS.CUSTOMER_DETAIL}/${payload.id}`;
      const { data } = await Http.get(url);
      if (data) {
        action.setDataCustomer(data.data ?? {});
      }
    } catch (err) {
      action.setDataCustomer({});
    } finally{
      action.setLoadingDetail(false);
    }
  }),

  createCustom: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error('Invalid Payload');
      }
      action.setLoadingSave(true);
      const url = ENDPOINTS.CUSTOM_LIST;
      const res = await Http.post(url, payload);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),
  updateCustomer: thunk(async (action, payload) => {
    try {
      if (!(payload !== null)) {
        throw new Error('Invalid Payload');
      }
      action.setLoadingSave(true);
      const url = ENDPOINTS.CUSTOM_LIST;
      const res = await Http.put(url, payload);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSave(false);
    }
  }),
};
