/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { LANGUAGES } from '../../constants';
import { getObjectByValue } from '../../common';

import './style.scss';

export const LanguageDropdown = ({ className = '', restDropdown, ...rest }) => {
  // For language
  const [t] = useTranslation('tlip');
  const setGlobalLanguage = useStoreActions(
    action => action.global.setGlobalLanguage
  );
  const globalLanguage = useStoreState(state => state.global.globalLanguage);

  /**
   * Render language dropdown
   *
   * @return {Object} - Element
   */
  const renderLanguageDropdown = () => {
    return (
      <Menu>
        {LANGUAGES.map(item => (
          <Menu.Item key={item.value} title={t(item.label)}>
            <a
              onClick={e => {
                e.preventDefault();
                setGlobalLanguage(item.value);
              }}
            >
              {item.flag}
            </a>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <div className={`c-language-dropdown ${className}`} {...rest}>
      {Array.isArray(LANGUAGES) && LANGUAGES.length > 0 && (
        <Dropdown
          overlay={renderLanguageDropdown()}
          trigger={['click']}
          placement="bottomLeft"
          overlayClassName="c-dropdown-language"
          {...restDropdown}
        >
          <div className="ant-dropdown-link cursor-pointer btn-flag">
            <span className="ic-flag">
              {getObjectByValue(globalLanguage, LANGUAGES)?.flag}
            </span>
            <CaretDownOutlined className="ic-arrow" />
          </div>
        </Dropdown>
      )}
    </div>
  );
};
